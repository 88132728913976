import React from "react";
import { connect } from "react-redux";
import ReactGA from "react-ga";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./App.css";
import "./styles/index.scss";
import LandingPage from "./containers/LandingPage";
import AuthPage from "./containers/AuthPage";

import SearchContainer from "./containers/SearchContainer";
import MyDashboard from "./containers/MyDashboard";
import ShortShoutMain from "./containers/ShortShoutMain";
import MasterAdmin from "./containers/MasterAdmin";
import requireUserAuth from "./components/auth/requireUserAuth";
import requireMasterAdminAuth from "./components/auth/requireMasterAdminAuth";
import hideForAuthUser from "./components/auth/hideForAuthUser";

import RouteChangeTracker from "./RouteChangeTracker";

import * as actions from "./redux/actions";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.props.isLoggedInUser();

    // Add your tracking ID created from https://analytics.google.com/analytics/web/#home/
    // if (process.env.NODE_ENV === "development") {
    // } else {
    //   ReactGA.initialize("UA-39862096-11");
    //   ReactGA.pageview(window.location.pathname + window.location.search);
    // }
  }
  render() {
    return (
      <Router>
        <Switch>
          <Route exact path="/" component={LandingPage} />
          <Route exact path="/login" component={hideForAuthUser(AuthPage)} />
          <Route exact path="/register" component={hideForAuthUser(AuthPage)} />
          <Route
            exact
            path="/forgotPassword"
            component={hideForAuthUser(AuthPage)}
          />
          <Route
            exact
            path="/resendVerificationLink"
            component={hideForAuthUser(AuthPage)}
          />
          <Route
            // exact
            path="/society/:locationName"
            component={SearchContainer}
          />

          <Route
            path="/(mydashboard|mydashboard-addNewParking|mydashboard-editProfile|mydashboard-updatePassword|mydashboard-request-new-location)/"
            component={requireUserAuth(MyDashboard)}
          />
          <Route
            path="/(admin|admin-manageLocations|admin-manageUsers|admin-manageParkings|admin-manageLocationsRequest)/"
            component={requireMasterAdminAuth(MasterAdmin)}
          />

          {/* <Route path="/:shortShoutUsername" component={ShortShoutMain} /> */}
        </Switch>
        <RouteChangeTracker />
      </Router>
    );
  }
}

//export default App;

const mapStateToProps = (state) => ({
  // loggedUserData: state.auth.login.data || false,
});

const mapDispatchToProps = (dispatch) => ({
  isLoggedInUser: (data) => dispatch(actions.isLoggedInUser(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
