import * as actions from "../actions";
import { call, put } from "redux-saga/effects";
import { fireApi, showNotification } from "../../services";

export function* getAllLocationsRequest(action) {
  const header = {};
  try {
    const response = yield call(
      fireApi,
      "POST",
      `/locations/getAllLocations`,
      {},
      header
    );
    if (response) {
      yield put(actions.getAllLocationsSuccess(response.data.data));
    }
  } catch (e) {
    console.log(e);
    yield put(actions.getAllLocationsError(e.response.data));
  }
}

export function* getLocationParkingsRequest(action) {
  const header = {};
  try {
    const response = yield call(
      fireApi,
      "POST",
      `/locations/getLocationParkings`,
      {
        locationId: action.payload.locationId,
      },
      header
    );
    if (response) {
      yield put(actions.getLocationParkingsSuccess(response.data.data));
    }
  } catch (e) {
    console.log(e);
    yield put(actions.getLocationParkingsError(e.response.data));
  }
}

export function* getLocationStatsRequest(action) {
  const header = {};
  try {
    const response = yield call(
      fireApi,
      "POST",
      `/locations/getLocationStats`,
      {
        locationId: action.payload.locationId,
      },
      header
    );
    if (response) {
      yield put(actions.getLocationStatsSuccess(response.data.data));
    }
  } catch (e) {
    console.log(e);
    yield put(actions.getLocationStatsError(e.response.data));
  }
}

export function* updateLocationStatusRequest(action) {
  const header = {};
  try {
    const response = yield call(
      fireApi,
      "POST",
      `/locations/updateLocationStatus`,
      action.payload,
      header
    );
    if (response) {
      yield put(actions.updateLocationStatusSuccess(response.data));
      yield put(actions.getAllLocationsRequest());
    }
  } catch (e) {
    yield put(actions.updateLocationStatusError(e.response.data));
  }
}

export function* updateLocationRequest(action) {
  const header = {};
  try {
    const response = yield call(
      fireApi,
      "POST",
      `/locations/updateLocation`,
      action.payload,
      header
    );
    if (response) {
      let { data } = response;
      if (data.error) {
        yield put(actions.updateLocationError(data));
        showNotification({
          type: "warning",
          title: data.message,
        });
      } else {
        yield put(actions.updateLocationSuccess(response.data));
        showNotification({
          type: "success",
          title: "Location updated successfully!!",
        });
        yield put(actions.getAllLocationsRequest());
      }
    }
  } catch (e) {
    yield put(actions.updateLocationError(e.response.data));
    showNotification({
      type: "error",
      title: "Location update Failed!!",
      message: e.response.data.message,
    });
  }
}

export function* newLocationRequest(action) {
  const header = {};
  try {
    const response = yield call(
      fireApi,
      "POST",
      `/locations/newLocationRequest`,
      action.payload,
      header
    );
    if (response) {
      showNotification({
        type: "success",
        title: response.data.message,
      });
      yield put(actions.newLocationSuccess(response.data.data));
    }
  } catch (e) {
    console.log(e);
    showNotification({
      type: "error",
      title: "Location request Failed!!",
      message: e.response.data.message,
    });
    yield put(actions.newLocationError(e.response.data));
  }
}
