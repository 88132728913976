import axios from "axios";
import jwt from "jsonwebtoken";
import { base_url, jwtsecret } from "./config";
import { notification } from "antd";

import { store } from "../redux/store";
import { logoutRequest } from "../redux/actions";

export const fireApi = async (method, api_url, data, headers) => {
  headers = {
    ...headers,
    Authorization: getUserToken(),
    Accept: "application/json",
    "Content-Type": "application/json",
    // withCredentials: true,
    "Cache-Control": "no-cache",
  };

  const url = base_url + api_url;
  let apiResponse = null;

  if (method === "GET") {
    apiResponse = axios.get(url, { headers });
  } else if (method === "POST") {
    apiResponse = axios.post(url, data, { headers });
  } else if (method === "PUT") {
    apiResponse = axios.put(url, data, { headers });
  } else if (method === "DELETE") {
    apiResponse = axios.delete(url, { data, headers });
  }

  return apiResponse
    .then((response) => {
      return response;
    })
    .catch((error) => {
      const { response } = error;
      const { status } = response;
      if (status === 401) {
        // clearLoggedInSession();
        // window.location.href = "/";
        store.dispatch(logoutRequest({}));
      }
    });
};

export const showNotification = ({ type, title, message }) => {
  notification[type]({
    duration: 2,
    message: title,
    description: message,
  });
};

export const setLoggedInSession = function (tokenVal) {
  localStorage.setItem("shortshout-auth", tokenVal);
};

export const clearLoggedInSession = function () {
  localStorage.removeItem("shortshout-auth");
  localStorage.removeItem("temp-isEmailVerified");
};

export const getLoggedUser = function () {
  let token = localStorage.getItem("shortshout-auth");
  if (token) {
    let decodedToken = jwt.verify(token, jwtsecret);
    if (decodedToken && decodedToken.email) {
      decodedToken.token = token;
      return decodedToken;
    }
  }
  return false;
};

export const getUserToken = function () {
  return localStorage.getItem("shortshout-auth") || "";
};

export const tempVariableToHold_isEmailVerified = function (val) {
  localStorage.setItem("temp-isEmailVerified", val);
};

export const get_tempVariableToHold_isEmailVerified = function () {
  return localStorage.getItem("temp-isEmailVerified") || false;
};

export const getUserShortShoutUrl = function (user) {
  return process.env.REACT_APP_WEB_BASE_URL + "/" + user.username;
};

const parseLocationObject = (locationObj) => {
  let fullName = "";
  if (locationObj && locationObj.locationName) {
    fullName =
      locationObj.locationName +
      ", " +
      locationObj.locationAddress +
      ", " +
      locationObj.locationCity +
      ", " +
      locationObj.locationState;
  }

  return {
    original: locationObj,
    fullName: fullName,
  };
};

const setPageTitle = (titleText) => {
  let title = "";
  if (titleText && titleText !== "") {
    title = titleText;
    title = title.replace(/,/g, " ");
  }

  if (title === "") {
    title = "Find Parking Space for Rent Exchange Sale - Parkinghunter.in";
  } else {
    title += " - ParkingHunter.in";
  }
  document.title = title;
};

export default {
  setPageTitle,
  parseLocationObject,
};
