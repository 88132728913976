import { combineReducers } from "redux";
import auth from "./auth/reducer";
import links from "./links/reducer";
import shortshout from "./shortshout/reducer";
import dashboard from "./dashboard/reducer";

import admin from "./admin/reducer";
import locations from "./locations/reducer";
import parkings from "./parkings/reducer";

const appReducer = combineReducers({
  auth,
  links,
  shortshout,
  dashboard,
  admin,
  locations,
  parkings,
});

const rootReducer = (state, action) => {
  return appReducer(state, action);
};

export default rootReducer;
