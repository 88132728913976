import React from "react";
import { Row, Col } from "antd";

class LocationDetails extends React.Component {
  render() {
    let { data } = this.props;
    return (
      <Row id="location-details">
        <Col md={24} xl={24} sm={24} xs={24}>
          <Row>
            <Col xl={12} md={12} xs={24}>
              <h1>{data.locationName}</h1>
              <h3>{data.locationAddress}</h3>
            </Col>
            <Col xl={12} md={12} xs={12}>
              <h3>{data.locationCity}</h3>
              <h3>{data.locationState}</h3>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}

export default LocationDetails;
