import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import LocationStats from "./LocationStats";
import LocationDetails from "./LocationDetails";
import LocationParkings from "./LocationParkings";

import * as actions from "../../redux/actions";

import SERVICES from "../../services";

class Search extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedLocationId: "",
    };
  }

  componentDidMount() {
    let locationId = "";
    const { match } = this.props;
    let locationName = (match && match.params.locationName) || "";
    if (locationName && locationName.trim() !== "") {
      let splitLocationName = locationName.split("-");
      if (splitLocationName.length > 0) {
        locationId = splitLocationName[splitLocationName.length - 1];
      }
    }
    if (locationId === "") {
      this.props.history.push("/");
    } else {
      this.setState(
        {
          selectedLocationId: locationId,
        },
        () => {
          this.props.getLocationParkingsRequest({
            locationId: locationId,
          });
        }
      );
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.locationParkings !== this.props.locationParkings) {
      const { locationParkings } = this.props;
      const { locationDetails } = locationParkings;
      let locationComplete = SERVICES.parseLocationObject(locationDetails);
      if (
        locationComplete &&
        locationComplete.fullName &&
        locationComplete.fullName !== ""
      ) {
        SERVICES.setPageTitle(locationComplete.fullName);
      }
    }
  }

  render() {
    const { selectedLocationId } = this.state;
    let { loggedUserData, locationParkings } = this.props;
    let parkingsToShow = [];
    if (
      locationParkings &&
      locationParkings.parkings &&
      locationParkings.parkings.length > 0
    ) {
      parkingsToShow = locationParkings.parkings;
    }

    return (
      <>
        {locationParkings.locationDetails &&
          locationParkings.locationDetails && (
            <LocationDetails data={locationParkings.locationDetails} />
          )}

        <LocationStats locationId={selectedLocationId} />
        <LocationParkings parkingsToShow={parkingsToShow} />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  locationParkings: state.locations.locationParkings.data || [],
  loggedUserData: state.auth.login.data || false,
});

const mapDispatchToProps = (dispatch) => ({
  getLocationParkingsRequest: (data) =>
    dispatch(actions.getLocationParkingsRequest(data)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Search));
