import { handleActions } from "redux-actions";
import update from "immutability-helper";
import constants from "../constants";

const initialState = {
  parkingsList: {
    isLoading: false,
    isSuccess: false,
    isError: false,
    message: "",
    data: [],
  },
  locationsSuggestionList: {
    isLoading: false,
    isSuccess: false,
    isError: false,
    message: "",
    data: [],
  },
  usersList: {
    isLoading: false,
    isSuccess: false,
    isError: false,
    message: "",
    data: [],
  },
  linksList: {
    isLoading: false,
    isSuccess: false,
    isError: false,
    message: "",
    data: [],
  },
  stats: {
    isLoading: false,
    isSuccess: false,
    isError: false,
    message: "",
    data: {},
  },
  sendDoVerificationEmail: {
    isLoading: false,
    isSuccess: false,
    isError: false,
    message: "",
    data: {},
  },
};

const adminGetParkingsRequest = (state, action) =>
  update(state, {
    parkingsList: {
      isLoading: { $set: true },
      isSuccess: { $set: false },
      isError: { $set: false },
      message: { $set: "" },
    },
  });

const adminGetParkingsSuccess = (state, action) =>
  update(state, {
    usersList: {
      isLoading: { $set: false },
      isSuccess: { $set: true },
      isError: { $set: false },
      message: { $set: "" },
      data: { $set: action.payload },
    },
  });

const adminGetParkingsError = (state, action) =>
  update(state, {
    usersList: {
      isLoading: { $set: false },
      isSuccess: { $set: false },
      isError: { $set: true },
      message: { $set: action.payload },
    },
  });

const adminGetUsersRequest = (state, action) =>
  update(state, {
    usersList: {
      isLoading: { $set: true },
      isSuccess: { $set: false },
      isError: { $set: false },
      message: { $set: "" },
    },
  });

const adminGetUsersSuccess = (state, action) =>
  update(state, {
    usersList: {
      isLoading: { $set: false },
      isSuccess: { $set: true },
      isError: { $set: false },
      message: { $set: "" },
      data: { $set: action.payload },
    },
  });

const adminGetUsersError = (state, action) =>
  update(state, {
    usersList: {
      isLoading: { $set: false },
      isSuccess: { $set: false },
      isError: { $set: true },
      message: { $set: action.payload },
    },
  });

const adminGetLatestLinksRequest = (state, action) =>
  update(state, {
    linksList: {
      isLoading: { $set: true },
      isSuccess: { $set: false },
      isError: { $set: false },
      message: { $set: "" },
    },
  });

const adminGetLatestLinksSuccess = (state, action) =>
  update(state, {
    linksList: {
      isLoading: { $set: false },
      isSuccess: { $set: true },
      isError: { $set: false },
      message: { $set: "" },
      data: { $set: action.payload },
    },
  });

const adminGetLatestLinksError = (state, action) =>
  update(state, {
    linksList: {
      isLoading: { $set: false },
      isSuccess: { $set: false },
      isError: { $set: true },
      message: { $set: action.payload },
    },
  });

const adminGetCompleteStatsRequest = (state, action) =>
  update(state, {
    stats: {
      isLoading: { $set: true },
      isSuccess: { $set: false },
      isError: { $set: false },
      message: { $set: "" },
    },
  });

const adminGetCompleteStatsSuccess = (state, action) =>
  update(state, {
    stats: {
      isLoading: { $set: false },
      isSuccess: { $set: true },
      isError: { $set: false },
      message: { $set: "" },
      data: { $set: action.payload },
    },
  });

const adminGetCompleteStatsError = (state, action) =>
  update(state, {
    stats: {
      isLoading: { $set: false },
      isSuccess: { $set: false },
      isError: { $set: true },
      message: { $set: action.payload },
    },
  });

/* admin send do verification email*/
const adminSendDoVerificationEmailRequest = (state, action) =>
  update(state, {
    sendDoVerificationEmail: {
      isLoading: { $set: true },
      isSuccess: { $set: false },
      isError: { $set: false },
      message: { $set: "" },
    },
  });

const adminSendDoVerificationEmailSuccess = (state, action) =>
  update(state, {
    sendDoVerificationEmail: {
      isLoading: { $set: false },
      isSuccess: { $set: true },
      isError: { $set: false },
      message: { $set: "" },
      data: { $set: action.payload },
    },
  });

const adminSendDoVerificationEmailError = (state, action) =>
  update(state, {
    sendDoVerificationEmail: {
      isLoading: { $set: false },
      isSuccess: { $set: false },
      isError: { $set: true },
      message: { $set: action.payload },
    },
  });

const adminGetLocationsSuggestionRequest = (state, action) =>
  update(state, {
    locationsSuggestionList: {
      isLoading: { $set: true },
      isSuccess: { $set: false },
      isError: { $set: false },
      message: { $set: "" },
    },
  });

const adminGetLocationsSuggestionSuccess = (state, action) =>
  update(state, {
    locationsSuggestionList: {
      isLoading: { $set: false },
      isSuccess: { $set: true },
      isError: { $set: false },
      message: { $set: "" },
      data: { $set: action.payload },
    },
  });

const adminGetLocationsSuggestionError = (state, action) =>
  update(state, {
    locationsSuggestionList: {
      isLoading: { $set: false },
      isSuccess: { $set: false },
      isError: { $set: true },
      message: { $set: action.payload },
    },
  });

export default handleActions(
  {
    [constants.ADMIN_GET_PARKINGS_REQUEST]: adminGetParkingsRequest,
    [constants.ADMIN_GET_PARKINGS_SUCCESS]: adminGetParkingsSuccess,
    [constants.ADMIN_GET_PARKINGS_ERROR]: adminGetParkingsError,

    [constants.ADMIN_GET_USERS_REQUEST]: adminGetUsersRequest,
    [constants.ADMIN_GET_USERS_SUCCESS]: adminGetUsersSuccess,
    [constants.ADMIN_GET_USERS_ERROR]: adminGetUsersError,

    [constants.ADMIN_GET_LATEST_LINKS_REQUEST]: adminGetLatestLinksRequest,
    [constants.ADMIN_GET_LATEST_LINKS_SUCCESS]: adminGetLatestLinksSuccess,
    [constants.ADMIN_GET_LATEST_LINKS_ERROR]: adminGetLatestLinksError,

    [constants.ADMIN_GET_COMPLETE_STATS_REQUEST]: adminGetCompleteStatsRequest,
    [constants.ADMIN_GET_COMPLETE_STATS_SUCCESS]: adminGetCompleteStatsSuccess,
    [constants.ADMIN_GET_COMPLETE_STATS_ERROR]: adminGetCompleteStatsError,

    [constants.ADMIN_GET_LOCATIONS_SUGGESTION_REQUEST]:
      adminGetLocationsSuggestionRequest,
    [constants.ADMIN_GET_LOCATIONS_SUGGESTION_SUCCESS]:
      adminGetLocationsSuggestionSuccess,
    [constants.ADMIN_GET_LOCATIONS_SUGGESTION_ERROR]:
      adminGetLocationsSuggestionError,

    [constants.ADMIN_SEND_DO_VERIFICATION_EMAIL_REQUEST]:
      adminSendDoVerificationEmailRequest,
    [constants.ADMIN_SEND_DO_VERIFICATION_EMAIL_SUCCESS]:
      adminSendDoVerificationEmailSuccess,
    [constants.ADMIN_SEND_DO_VERIFICATION_EMAIL_ERROR]:
      adminSendDoVerificationEmailError,
  },
  initialState
);
