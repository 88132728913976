import React from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { HomeOutlined } from "@ant-design/icons";

import * as actions from "../../redux/actions";

import { Button } from "antd";
// import SideDrawer from "../SideDrawer";

class Auth extends React.Component {
  state = { visible: false };

  constructor(props) {
    super(props);
    this.props.isLoggedInUser();
    this.state = {
      // openLoginForm: false,
      // openRegisterForm: false,
      // openSideDrawer: false,
      // sideDrawerType: "",
      // sideDrawerTitle: "",
    };
  }

  componentDidUpdate(prevProps) {
    // if (
    //   this.props.login.data &&
    //   this.props.login.data !== prevProps.login.data
    // ) {
    //   this.setState({
    //     openSideDrawer: false,
    //   });
    // }
    // if (
    //   this.props.login.isSuccess &&
    //   prevProps.login.isSuccess != this.props.login.isSuccess
    // ) {
    //   if (
    //     this.props.login &&
    //     this.props.login.data &&
    //     this.props.login.data.role
    //   ) {
    //     if (this.props.login.data.role == "masteradmin") {
    //       this.props.history.push("/mydashboard");
    //     } else {
    //       this.props.history.push("/mydashboard");
    //     }
    //   }
    // }
  }

  _doLogout = (values) => {
    this.props.logoutRequest();
  };

  render() {
    let { loggedUserData } = this.props;
    let loggedUserRole = loggedUserData.role;
    return (
      <div>
        {loggedUserData &&
        loggedUserData.token &&
        loggedUserData.email &&
        loggedUserData.name ? (
          <div>
            <b>Hi {loggedUserData.name}</b>, &nbsp;
            <Link to="/">
              <HomeOutlined />
            </Link>
            &nbsp; | &nbsp;
            <Link to="/mydashboard">
              <Button type="link">My Dashboard</Button>
            </Link>
            &nbsp; | &nbsp;
            {loggedUserRole === "masteradmin" ? (
              <>
                <Link to="/admin">
                  <Button type="link">Admin</Button>
                </Link>
                &nbsp; | &nbsp;
              </>
            ) : null}
            <a onClick={this._doLogout}>Log Out</a>
          </div>
        ) : (
          <>
            <Link to="/login">
              <Button type="link" style={{ fontWeight: "bold" }}>
                Sign In
              </Button>
            </Link>
            &nbsp;&nbsp;&nbsp;
            <Link to="/register">
              <Button type="link" style={{ fontWeight: "bold" }}>
                Sign Up
              </Button>
            </Link>
          </>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loggedUserData: state.auth.login.data || false,
});

const mapDispatchToProps = (dispatch) => ({
  isLoggedInUser: (data) => dispatch(actions.isLoggedInUser(data)),
  logoutRequest: (data) => dispatch(actions.logoutRequest(data)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Auth));
