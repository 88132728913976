import { handleActions } from "redux-actions";
import update from "immutability-helper";
import constants from "../constants";

const initialState = {
  register: {
    isLoading: false,
    isSuccess: false,
    isError: false,
    message: "",
    data: {},
  },
  login: {
    isLoading: false,
    isSuccess: false,
    isError: false,
    message: "",
    data: false,
  },
  resetPassword: {
    isLoading: false,
    isSuccess: false,
    isError: false,
    message: "",
    data: false,
  },
  verifyEmail: {
    isLoading: false,
    isSuccess: false,
    isError: false,
    message: "",
    data: false,
  },
  sendEmailVerifyCode: {
    isLoading: false,
    isSuccess: false,
    isError: false,
    message: "",
    data: false,
  },
  myProfile: {
    isLoading: false,
    isSuccess: false,
    isError: false,
    message: "",
    data: false,
  },
  updateMyProfile: {
    isLoading: false,
    isSuccess: false,
    isError: false,
    message: "",
    data: false,
  },
  updatePassword: {
    isLoading: false,
    isSuccess: false,
    isError: false,
    message: "",
    data: false,
  },
};

/* REGISTER */
const registerRequest = (state, action) =>
  update(state, {
    register: {
      isLoading: { $set: true },
      isSuccess: { $set: false },
      isError: { $set: false },
      message: { $set: "" },
    },
  });

const registerSuccess = (state, action) =>
  update(state, {
    register: {
      isLoading: { $set: false },
      isSuccess: { $set: true },
      isError: { $set: false },
      message: { $set: "Register Successfully!!" },
      data: { $set: action.payload },
    },
  });

const registerError = (state, action) =>
  update(state, {
    register: {
      isLoading: { $set: false },
      isSuccess: { $set: false },
      isError: { $set: true },
      message: { $set: action.payload },
    },
  });
/* REGISTER */

/* LOGIN */
const loginRequest = (state, action) =>
  update(state, {
    login: {
      isLoading: { $set: true },
      isSuccess: { $set: false },
      isError: { $set: false },
      message: { $set: "" },
      data: { $set: false },
    },
  });

const loginSuccess = (state, action) =>
  update(state, {
    login: {
      isLoading: { $set: false },
      isSuccess: { $set: true },
      isError: { $set: false },
      message: { $set: "Login Successfully!" },
      data: { $set: action.payload },
    },
  });

const loginError = (state, action) =>
  update(state, {
    login: {
      isLoading: { $set: false },
      isSuccess: { $set: false },
      isError: { $set: true },
      message: { $set: action.payload },
      data: { $set: false },
    },
  });
/* LOGIN */

/* LOG OUT*/
const logoutRequest = (state, action) =>
  update(state, {
    login: {
      isLoading: { $set: false },
      isSuccess: { $set: false },
      isError: { $set: false },
      message: { $set: "" },
      data: { $set: false },
    },
  });
/* LOG OUT*/

/* reset password */
const resetPasswordRequest = (state, action) =>
  update(state, {
    resetPassword: {
      isLoading: { $set: true },
      isSuccess: { $set: false },
      isError: { $set: false },
      message: { $set: "" },
      data: { $set: false },
    },
  });

const resetPasswordSuccess = (state, action) =>
  update(state, {
    resetPassword: {
      isLoading: { $set: false },
      isSuccess: { $set: true },
      isError: { $set: false },
      message: { $set: "Login Successfully!" },
      data: { $set: action.payload },
    },
  });

const resetPasswordError = (state, action) =>
  update(state, {
    resetPassword: {
      isLoading: { $set: false },
      isSuccess: { $set: false },
      isError: { $set: true },
      message: { $set: action.payload },
      data: { $set: false },
    },
  });
/* reset password */

/* verify email */
const verifyEmailRequest = (state, action) =>
  update(state, {
    verifyEmail: {
      isLoading: { $set: true },
      isSuccess: { $set: false },
      isError: { $set: false },
      message: { $set: "" },
      data: { $set: false },
    },
  });

const verifyEmailSuccess = (state, action) =>
  update(state, {
    verifyEmail: {
      isLoading: { $set: false },
      isSuccess: { $set: true },
      isError: { $set: false },
      message: { $set: "Login Successfully!" },
      data: { $set: action.payload },
    },
  });

const verifyEmailError = (state, action) =>
  update(state, {
    verifyEmail: {
      isLoading: { $set: false },
      isSuccess: { $set: false },
      isError: { $set: true },
      message: { $set: action.payload },
      data: { $set: false },
    },
  });
/* verify email */

/* send Email Verify Code  */
const sendEmailVerifyCodeRequest = (state, action) =>
  update(state, {
    sendEmailVerifyCode: {
      isLoading: { $set: true },
      isSuccess: { $set: false },
      isError: { $set: false },
      message: { $set: "" },
      data: { $set: false },
    },
  });

const sendEmailVerifyCodeSuccess = (state, action) =>
  update(state, {
    sendEmailVerifyCode: {
      isLoading: { $set: false },
      isSuccess: { $set: true },
      isError: { $set: false },
      message: { $set: "Login Successfully!" },
      data: { $set: action.payload },
    },
  });

const sendEmailVerifyCodeError = (state, action) =>
  update(state, {
    sendEmailVerifyCode: {
      isLoading: { $set: false },
      isSuccess: { $set: false },
      isError: { $set: true },
      message: { $set: action.payload },
      data: { $set: false },
    },
  });
/* send Email Verify Code  */

/* get my profile  */
const getMyProfileRequest = (state, action) =>
  update(state, {
    myProfile: {
      isLoading: { $set: true },
      isSuccess: { $set: false },
      isError: { $set: false },
      message: { $set: "" },
      data: { $set: false },
    },
  });

const getMyProfileSuccess = (state, action) =>
  update(state, {
    myProfile: {
      isLoading: { $set: false },
      isSuccess: { $set: true },
      isError: { $set: false },
      message: { $set: "Login Successfully!" },
      data: { $set: action.payload },
    },
  });

const getMyProfileError = (state, action) =>
  update(state, {
    myProfile: {
      isLoading: { $set: false },
      isSuccess: { $set: false },
      isError: { $set: true },
      message: { $set: action.payload },
      data: { $set: false },
    },
  });

/* update my profile  */
const updateMyProfileRequest = (state, action) =>
  update(state, {
    updateMyProfile: {
      isLoading: { $set: true },
      isSuccess: { $set: false },
      isError: { $set: false },
      message: { $set: "" },
      data: { $set: false },
    },
  });

const updateMyProfileSuccess = (state, action) =>
  update(state, {
    updateMyProfile: {
      isLoading: { $set: false },
      isSuccess: { $set: true },
      isError: { $set: false },
      message: { $set: "Login Successfully!" },
      data: { $set: action.payload },
    },
  });

const updateMyProfileError = (state, action) =>
  update(state, {
    updateMyProfile: {
      isLoading: { $set: false },
      isSuccess: { $set: false },
      isError: { $set: true },
      message: { $set: action.payload },
      data: { $set: false },
    },
  });

/* update password  */
const updatePasswordRequest = (state, action) =>
  update(state, {
    updatePassword: {
      isLoading: { $set: true },
      isSuccess: { $set: false },
      isError: { $set: false },
      message: { $set: "" },
      data: { $set: false },
    },
  });

const updatePasswordSuccess = (state, action) =>
  update(state, {
    updatePassword: {
      isLoading: { $set: false },
      isSuccess: { $set: true },
      isError: { $set: false },
      message: { $set: "Password updated Successfully!" },
      data: { $set: action.payload },
    },
  });

const updatePasswordError = (state, action) =>
  update(state, {
    updatePassword: {
      isLoading: { $set: false },
      isSuccess: { $set: false },
      isError: { $set: true },
      message: { $set: action.payload },
      data: { $set: false },
    },
  });

export default handleActions(
  {
    [constants.REGISTER_REQUEST]: registerRequest,
    [constants.REGISTER_SUCCESS]: registerSuccess,
    [constants.REGISTER_ERROR]: registerError,

    [constants.LOGIN_REQUEST]: loginRequest,
    [constants.LOGIN_SUCCESS]: loginSuccess,
    [constants.LOGIN_ERROR]: loginError,

    [constants.LOGOUT_REQUEST]: logoutRequest,

    [constants.RESET_PASSWORD_REQUEST]: resetPasswordRequest,
    [constants.RESET_PASSWORD_SUCCESS]: resetPasswordSuccess,
    [constants.RESET_PASSWORD_ERROR]: resetPasswordError,

    [constants.VERIFY_EMAIL_REQUEST]: verifyEmailRequest,
    [constants.VERIFY_EMAIL_SUCCESS]: verifyEmailSuccess,
    [constants.VERIFY_EMAIL_ERROR]: verifyEmailError,

    [constants.SEND_EMAIL_VERIFY_CODE_REQUEST]: sendEmailVerifyCodeRequest,
    [constants.SEND_EMAIL_VERIFY_CODE_SUCCESS]: sendEmailVerifyCodeSuccess,
    [constants.SEND_EMAIL_VERIFY_CODE_ERROR]: sendEmailVerifyCodeError,

    [constants.GET_MY_PROFILE_REQUEST]: getMyProfileRequest,
    [constants.GET_MY_PROFILE_SUCCESS]: getMyProfileSuccess,
    [constants.GET_MY_PROFILE_ERROR]: getMyProfileError,

    [constants.UPDATE_MY_PROFILE_REQUEST]: updateMyProfileRequest,
    [constants.UPDATE_MY_PROFILE_SUCCESS]: updateMyProfileSuccess,
    [constants.UPDATE_MY_PROFILE_ERROR]: updateMyProfileError,

    [constants.UPDATE_PASSWORD_REQUEST]: updatePasswordRequest,
    [constants.UPDATE_PASSWORD_SUCCESS]: updatePasswordSuccess,
    [constants.UPDATE_PASSWORD_ERROR]: updatePasswordError,
  },
  initialState
);
