import React from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { Row, Col, Layout, Button } from "antd";
import HeaderMain from "../components/HeaderMain";
import FooterMain from "../components/FooterMain";

import Admin from "../components/admin";
import ManageLocations from "../components/admin/locations";
import ManageUsers from "../components/admin/users";
import ManageParkings from "../components/admin/parkings";
import ManageLocationsRequest from "../components/admin/locationsRequest";

const { Header, Content, Footer } = Layout;

class MasterAdmin extends React.Component {
  render() {
    return (
      <Layout id="masteradmin-page">
        <HeaderMain />
        <Content className="page-content">
          <div id="menu-list">
            <Link to="/admin">
              <Button type="link">Admin Dashboard</Button>
            </Link>
            <Link to="/admin-manageLocations">
              <Button type="link">Manage Locations</Button>
            </Link>
            <Link to="/admin-manageUsers">
              <Button type="link">Manage Users</Button>
            </Link>
            <Link to="/admin-manageParkings">
              <Button type="link">Manage Parkings</Button>
            </Link>
            <Link to="/admin-manageLocationsRequest">
              <Button type="link">Manage Locations Request</Button>
            </Link>
          </div>

          <div className="sub-pages">
            <Route>
              <Route exact path="/admin" component={Admin} />
              <Route
                path="/admin-manageLocations"
                component={ManageLocations}
              />
              <Route path="/admin-manageUsers" component={ManageUsers} />
              <Route path="/admin-manageParkings" component={ManageParkings} />
              <Route
                path="/admin-manageLocationsRequest"
                component={ManageLocationsRequest}
              />
            </Route>
          </div>

          {/* <Route>
            <Route path="/masteradmin/dashboard" component={Admin} />
            <Route
              path="/masteradmin/managelocations"
              component={ManageLocations}
            />
            {/* <Route
                path="/masteradmin/manageLocations"
                component={ManageLocations}
              /> */}
          {/* </Route> */}

          {/* <Switch> */}
          {/* <Route exact path="/masteradmin" component={Admin} />
          <Route
            path="/masteradmin/manageLocations"
            component={ManageLocations}
          /> */}
          {/* <Route
                exact
                path="/manageLocations"
                component={ManageLocations}
              /> */}
          {/* <Route
                exact
                path="/masteradmin"
                component={requireMasterAdminAuth(MasterAdmin)}
              />
              <Route path="/:shortShoutUsername" component={ShortShoutMain} /> */}
          {/* </Switch> */}
        </Content>
        <FooterMain />
      </Layout>
    );
  }
}

export default MasterAdmin;
