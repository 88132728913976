import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Form, Input, Select, Checkbox, Button, Spin } from "antd";

import * as actions from "../../redux/actions";

const { Option } = Select;

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

class FormRegister extends React.Component {
  // onFinish = (values) => {
  //   this.props.doRegister(values);
  // };

  _doRegister = (data) => {
    this.props.registerRequest(data);
  };

  render() {
    let { isLoading } = this.props.register;
    return (
      <div id="form-register">
        <div className="heading-1">Sign Up</div>
        <Form
          {...formItemLayout}
          // form={form}
          name="register"
          onFinish={this._doRegister}
          initialValues={{
            residence: ["zhejiang", "hangzhou", "xihu"],
            prefix: "86",
          }}
          scrollToFirstError
        >
          <Form.Item
            name="name"
            label="Full Name"
            rules={[
              {
                required: true,
                message: "Enter your full name",
              },
            ]}
          >
            <Input autoComplete={false} />
          </Form.Item>
          <Form.Item
            name="email"
            label="E-mail"
            rules={[
              {
                type: "email",
                message: "The input is not valid E-mail!",
              },
              {
                required: true,
                message: "Please input your E-mail!",
              },
            ]}
          >
            <Input />
          </Form.Item>

          {/* <Form.Item
            name="mobileNumber"
            label="Mobile Number"
            rules={[
              {
                required: true,
                message: "Enter mobile number",
              },
              {
                message: "Enter valid mobile number",
                pattern: /^\d{10}$/,
              },
            ]}
          >
            <Input autoComplete={false} />
          </Form.Item> */}

          <Form.Item
            name="password"
            label="Password"
            rules={[
              {
                required: true,
                message: "Please input your password!",
              },
              {
                min: 4,
                message: "Password cannot be less than 4 characters",
              },
            ]}
            hasFeedback
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
            name="confirm"
            label="Confirm Password"
            dependencies={["password"]}
            hasFeedback
            rules={[
              {
                required: true,
                message: "Please confirm your password!",
              },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }

                  return Promise.reject(
                    "The two passwords that you entered do not match!"
                  );
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item>
          <br />
          <Form.Item
            name="agreement"
            valuePropName="checked"
            rules={[
              {
                validator: (_, value) =>
                  value
                    ? Promise.resolve()
                    : Promise.reject("You need to give permission"),
              },
            ]}
            {...tailFormItemLayout}
          >
            {/* <Checkbox>
              Yes, I would like to receive emails, calls or sms on my provided
              email and mobile number.
            </Checkbox> */}
            <Checkbox>
              Yes, I would like to receive emails on my provided email.
            </Checkbox>
          </Form.Item>
          <Form.Item {...tailFormItemLayout}>
            {isLoading ? (
              <Spin />
            ) : (
              <Button type="primary" htmlType="submit">
                Sign Up
              </Button>
            )}
          </Form.Item>
        </Form>
      </div>
    );
  }
}

// export default RegisterForm;

const mapStateToProps = (state) => ({
  login: state.auth.login,
  register: state.auth.register,
  resetPassword: state.auth.resetPassword,
  loggedUserData: state.auth.login.data || false,
});

const mapDispatchToProps = (dispatch) => ({
  registerRequest: (data) => dispatch(actions.registerRequest(data)),
  loginRequest: (data) => dispatch(actions.loginRequest(data)),
  isLoggedInUser: (data) => dispatch(actions.isLoggedInUser(data)),
  logoutRequest: (data) => dispatch(actions.logoutRequest(data)),
  resetPasswordRequest: (data) => dispatch(actions.resetPasswordRequest(data)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(FormRegister)
);
