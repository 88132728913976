import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Button, Row, Col, Form, Input } from "antd";

// import MyParkings from "./MyParkings";
import { UserOutlined, LockOutlined } from "@ant-design/icons";

// import FormAddNewLocation from "./FormAddNewLocation";
import Users from "./Users";

import * as actions from "../../../redux/actions";

import { get_tempVariableToHold_isEmailVerified } from "../../../services";

class ManageUsers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openNewParkingForm: false,
    };
  }

  componentDidMount() {
    if (this.props.loggedUserData) {
      // this.props.myParkingsRequest();
    }
  }

  _openNewParkingForm = () => {
    this.setState({
      openNewParkingForm: true,
    });
  };

  onFinish = (values) => {
    console.log(values);
    this.props.adminNewLocationRequest(values);
  };

  render() {
    let { loggedUserData, myParkings } = this.props;

    let isEmailVerified =
      (loggedUserData && loggedUserData.isEmailVerified) || false;
    if (isEmailVerified === false) {
      isEmailVerified = get_tempVariableToHold_isEmailVerified();
    }

    // locationName: { type: String }, // society name
    // locationCity: { type: String }, // sector, city etc.
    // locationState: { type: String }, // state
    // locationCountry: { type: String },

    return (
      <>
        <Row className="mydashboard-menu">
          <Col xl={24} md={24} xs={24}>
            {/* <FormAddNewLocation /> */}
            <br />
            <Users />
          </Col>
        </Row>
        {/* <MyParkings myParkings={myParkings} /> */}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  myParkings: state.parkings.myParkings.data || [],
  loggedUserData: state.auth.login.data || false,
});

const mapDispatchToProps = (dispatch) => ({
  myParkingsRequest: (data) => dispatch(actions.myParkingsRequest(data)),
  adminNewLocationRequest: (data) =>
    dispatch(actions.adminNewLocationRequest(data)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ManageUsers)
);
