import React from "react";
import { Layout } from "antd";
import HeaderMain from "../components/HeaderMain";
import FooterMain from "../components/FooterMain";

import Landing from "../components/landing";

const { Content } = Layout;

class LandingPage extends React.Component {
  render() {
    return (
      <Layout id="landing-page">
        <HeaderMain />
        <Content className="page-content">
          <Landing />
        </Content>
        <FooterMain />
      </Layout>
    );
  }
}

export default LandingPage;
