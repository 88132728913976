import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import { Layout } from "antd";
import ShortShout from "../components/shortshout";
import * as actions from "../redux/actions";

const { Content } = Layout;

class ShortShoutMain extends React.Component {
  componentDidMount() {
    const { shortShoutUsername } = this.props.match.params;
    this.props.getPublicShortShoutRequest({
      username: shortShoutUsername,
    });
    if (shortShoutUsername && shortShoutUsername.trim() !== "") {
      document.title = "Shortshout.in - " + shortShoutUsername;
    }
  }
  render() {
    return (
      <div>
        <Content className="page-content">
          <ShortShout
            showBorder={false}
            username={this.props.username}
            links={this.props.links}
          />
        </Content>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  links:
    (state.shortshout.publicShortShout &&
      state.shortshout.publicShortShout.data &&
      state.shortshout.publicShortShout.data.links) ||
    false,
  username:
    (state.shortshout.publicShortShout &&
      state.shortshout.publicShortShout.data &&
      state.shortshout.publicShortShout.data.username) ||
    false,
});

const mapDispatchToProps = (dispatch) => ({
  getPublicShortShoutRequest: (data) =>
    dispatch(actions.getPublicShortShoutRequest(data)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ShortShoutMain)
);
