import React from "react";
import { withRouter, Link } from "react-router-dom";

import { Row, Col, Layout } from "antd";
import Auth from "./auth";
import Logo from "../images/logo.png";
const { Header, Footer } = Layout;

class HeaderMain extends React.Component {
  render() {
    return (
      <div id="main-sticky-header" style={{}}>
        {/* <div className="bg-header"></div> */}
        <Row className="fix-header-row">
          <Col xl={12} md={12} xs={24}>
            <Link to="/">
              <img className="logo" src={Logo} />
            </Link>
          </Col>
          <Col
            className="auth-block"
            xl={12}
            md={12}
            xs={24}
            // style={{ textAlign: "right", paddingRight: "20px" }}
          >
            <Auth />
          </Col>
        </Row>
      </div>
    );
  }
}

export default withRouter(HeaderMain);
