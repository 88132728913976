import { handleActions } from "redux-actions";
import update from "immutability-helper";
import constants from "../constants";

const initialState = {
  newParking: {
    isLoading: false,
    isSuccess: false,
    isError: false,
    message: "",
    data: {},
  },
  myParkings: {
    isLoading: false,
    isSuccess: false,
    isError: false,
    message: "",
    data: [],
  },
  deleteParking: {
    isLoading: false,
    isSuccess: false,
    isError: false,
    message: "",
    data: {},
  },
  updateParkingStatus: {
    isLoading: false,
    isSuccess: false,
    isError: false,
    message: "",
    data: {},
  },
  updateParking: {
    isLoading: false,
    isSuccess: false,
    isError: false,
    message: "",
    data: {},
  },
};

/* new parking */
const newParkingRequest = (state, action) =>
  update(state, {
    newParking: {
      isLoading: { $set: true },
      isSuccess: { $set: false },
      isError: { $set: false },
      message: { $set: "" },
    },
  });

const newParkingSuccess = (state, action) =>
  update(state, {
    newParking: {
      isLoading: { $set: false },
      isSuccess: { $set: true },
      isError: { $set: false },
      message: { $set: "Parking added successfully!!" },
      data: { $set: action.payload },
    },
  });

const newParkingError = (state, action) =>
  update(state, {
    newParking: {
      isLoading: { $set: false },
      isSuccess: { $set: false },
      isError: { $set: true },
      message: { $set: action.payload },
    },
  });

/* my parkings */
const myParkingsRequest = (state, action) =>
  update(state, {
    myParkings: {
      isLoading: { $set: true },
      isSuccess: { $set: false },
      isError: { $set: false },
      message: { $set: "" },
    },
  });

const myParkingsSuccess = (state, action) =>
  update(state, {
    myParkings: {
      isLoading: { $set: false },
      isSuccess: { $set: true },
      isError: { $set: false },
      message: { $set: "" },
      data: { $set: action.payload },
    },
  });

const myParkingsError = (state, action) =>
  update(state, {
    myParkings: {
      isLoading: { $set: false },
      isSuccess: { $set: false },
      isError: { $set: true },
      message: { $set: action.payload },
    },
  });

/* delete parking */
const deleteParkingRequest = (state, action) =>
  update(state, {
    deleteParking: {
      isLoading: { $set: true },
      isSuccess: { $set: false },
      isError: { $set: false },
      message: { $set: "" },
    },
  });

const deleteParkingSuccess = (state, action) =>
  update(state, {
    deleteParking: {
      isLoading: { $set: false },
      isSuccess: { $set: true },
      isError: { $set: false },
      message: { $set: "Parking added successfully!!" },
      data: { $set: action.payload },
    },
  });

const deleteParkingError = (state, action) =>
  update(state, {
    deleteLink: {
      isLoading: { $set: false },
      isSuccess: { $set: false },
      isError: { $set: true },
      message: { $set: action.payload },
    },
  });

/* update parking status */
const updateParkingStatusRequest = (state, action) =>
  update(state, {
    updateParkingStatus: {
      isLoading: { $set: true },
      isSuccess: { $set: false },
      isError: { $set: false },
      message: { $set: "" },
    },
  });

const updateParkingStatusSuccess = (state, action) =>
  update(state, {
    updateParkingStatus: {
      isLoading: { $set: false },
      isSuccess: { $set: true },
      isError: { $set: false },
      message: { $set: "Link status updated successfully!!" },
      data: { $set: action.payload },
    },
  });

const updateParkingStatusError = (state, action) =>
  update(state, {
    updateParkingStatus: {
      isLoading: { $set: false },
      isSuccess: { $set: false },
      isError: { $set: true },
      message: { $set: action.payload },
    },
  });

/* update parking */
const updateParkingRequest = (state, action) =>
  update(state, {
    updateParking: {
      isLoading: { $set: true },
      isSuccess: { $set: false },
      isError: { $set: false },
      message: { $set: "" },
    },
  });

const updateParkingSuccess = (state, action) =>
  update(state, {
    updateParking: {
      isLoading: { $set: false },
      isSuccess: { $set: true },
      isError: { $set: false },
      message: { $set: "Link added successfully!!" },
      data: { $set: action.payload },
    },
  });

const updateParkingError = (state, action) =>
  update(state, {
    updateParking: {
      isLoading: { $set: false },
      isSuccess: { $set: false },
      isError: { $set: true },
      message: { $set: action.payload },
    },
  });

export default handleActions(
  {
    [constants.NEW_PARKING_REQUEST]: newParkingRequest,
    [constants.NEW_PARKING_SUCCESS]: newParkingSuccess,
    [constants.NEW_PARKING_ERROR]: newParkingError,

    [constants.MY_PARKINGS_REQUEST]: myParkingsRequest,
    [constants.MY_PARKINGS_SUCCESS]: myParkingsSuccess,
    [constants.MY_PARKINGS_ERROR]: myParkingsError,

    [constants.DELETE_PARKING_REQUEST]: deleteParkingRequest,
    [constants.DELETE_PARKING_SUCCESS]: deleteParkingSuccess,
    [constants.DELETE_PARKING_ERROR]: deleteParkingError,

    [constants.UPDATE_PARKING_STATUS_REQUEST]: updateParkingStatusRequest,
    [constants.UPDATE_PARKING_STATUS_SUCCESS]: updateParkingStatusSuccess,
    [constants.UPDATE_PARKING_STATUS_ERROR]: updateParkingStatusError,

    [constants.UPDATE_PARKING_REQUEST]: updateParkingRequest,
    [constants.UPDATE_PARKING_SUCCESS]: updateParkingSuccess,
    [constants.UPDATE_PARKING_ERROR]: updateParkingError,
  },
  initialState
);
