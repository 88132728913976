import React from "react";
import { Form, Input, Button, Spin } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";

import * as actions from "../../redux/actions";

function FormResendVerificationLink(props) {
  const doResendVerificationLink = (data) => {
    console.log(props);
    props.sendEmailVerifyCodeRequest(data);
  };
  let { isLoading } = props.login;
  let isResetPasswordLoading = props.resetPassword.isLoading;
  return (
    <div id="form-login">
      <div className="heading-1">Re-send verification link</div>

      <Form
        name="resendVerificationLink"
        className="login-form"
        initialValues={{
          remember: true,
        }}
        onFinish={doResendVerificationLink}
      >
        <Form.Item
          name="email"
          rules={[
            {
              type: "email",
              message: "The input is not valid E-mail!",
            },
            {
              required: true,
              message: "Please input your E-mail!",
            },
          ]}
        >
          <Input
            prefix={<UserOutlined className="site-form-item-icon" />}
            placeholder="Enter your registered email"
          />
        </Form.Item>

        <Form.Item>
          {isResetPasswordLoading ? (
            <Spin />
          ) : (
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button"
            >
              Re-send verification link
            </Button>
          )}
        </Form.Item>
        <Form.Item>
          <Link to="/login">Sign In !!</Link>
        </Form.Item>
      </Form>
    </div>
  );
}

// export default LoginForm;

const mapStateToProps = (state) => ({
  login: state.auth.login,
  register: state.auth.register,
  resetPassword: state.auth.resetPassword,
  loggedUserData: state.auth.login.data || false,
});

const mapDispatchToProps = (dispatch) => ({
  registerRequest: (data) => dispatch(actions.registerRequest(data)),
  loginRequest: (data) => dispatch(actions.loginRequest(data)),
  isLoggedInUser: (data) => dispatch(actions.isLoggedInUser(data)),
  logoutRequest: (data) => dispatch(actions.logoutRequest(data)),
  resetPasswordRequest: (data) => dispatch(actions.resetPasswordRequest(data)),
  sendEmailVerifyCodeRequest: (data) =>
    dispatch(actions.sendEmailVerifyCodeRequest(data)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(FormResendVerificationLink)
);
