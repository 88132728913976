import React from "react";
import { Row, Col } from "antd";
import ParkingBlock from "./ParkingBlock";

class LocationParkings extends React.Component {
  render() {
    const { parkingsToShow } = this.props;

    return (
      <Row id="location-parkings">
        <Col xl={24} md={24} xs={24}>
          <h3>Parkings:</h3>
          {parkingsToShow.map((parking, i) => {
            return (
              <ParkingBlock
                key={i}
                data={parking}
                // doDeleteParking={this._onDeleteParking}
                doParkingStatusChange={this._doParkingStatusChange}
                // onEditParking={this._onEditParking}
              />
            );
          })}
        </Col>
      </Row>
    );
  }
}

export default LocationParkings;
