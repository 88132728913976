import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import { Layout } from "antd";
import HeaderMain from "../components/HeaderMain";
import FooterMain from "../components/FooterMain";

import FormLogin from "../components/auth/FormLogin";
import FormRegister from "../components/auth/FormRegister";
import FormForgotPassword from "../components/auth/FormForgotPassword";
import FormResendVerificationLink from "../components/auth/FormResendVerificationLink";

const { Content } = Layout;

class LandingPage extends React.Component {
  render() {
    return (
      <Layout id="authpage-page">
        <HeaderMain />
        <Content className="page-content">
          <Route>
            <Route path="/login" component={FormLogin} />
            <Route path="/register" component={FormRegister} />
            <Route path="/forgotPassword" component={FormForgotPassword} />
            <Route
              path="/resendVerificationLink"
              component={FormResendVerificationLink}
            />
            {/* <Route
              path="/masteradmin/managelocations"
              component={ManageLocations}
            /> */}
            {/* <Route
                path="/masteradmin/manageLocations"
                component={ManageLocations}
              /> */}
          </Route>
        </Content>
        <FooterMain />
      </Layout>
    );
  }
}

export default LandingPage;
