import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Statistic, Row, Col } from "antd";

import * as actions from "../../redux/actions";

class LocationStats extends React.Component {
  componentDidUpdate(prevProps) {
    if (prevProps.locationId !== this.props.locationId) {
      this.props.getLocationStatsRequest({
        locationId: this.props.locationId,
      });
    }
  }

  render() {
    const { locationStats } = this.props;
    return (
      <Row id="location-stats">
        <Col md={24} xl={24} sm={24} xs={24}>
          <Row>
            <Col xl={6} md={6} xs={12}>
              <Statistic
                title="Total Parkings Listed"
                value={locationStats.total_parkings || 0}
              />
            </Col>
            <Col xl={6} md={6} xs={12}>
              <Statistic
                title="For Exchange"
                value={locationStats.total_exchange || 0}
              />
            </Col>
            <Col xl={6} md={6} xs={12}>
              <Statistic
                title="For Rent"
                value={locationStats.total_rent || 0}
              />
            </Col>
            <Col xl={6} md={6} xs={12}>
              <Statistic
                title="For Sale"
                value={locationStats.total_sale || 0}
              />
            </Col>
            {/* <Col xl={6} md={6} xs={12}>
              <Statistic
                title="Parking Settled"
                value={locationStats.total_settled || 0}
              />
            </Col> */}
          </Row>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (state) => ({
  locationStats: state.locations.locationStats.data || [],
  loggedUserData: state.auth.login.data || false,
});

const mapDispatchToProps = (dispatch) => ({
  getLocationStatsRequest: (data) =>
    dispatch(actions.getLocationStatsRequest(data)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LocationStats)
);
