import { handleActions } from "redux-actions";
import update from "immutability-helper";
import constants from "../constants";

const initialState = {
  locations: {
    isLoading: false,
    isSuccess: false,
    isError: false,
    message: "",
    data: [],
  },
  locationParkings: {
    isLoading: false,
    isSuccess: false,
    isError: false,
    message: "",
    data: [],
  },
  locationStats: {
    isLoading: false,
    isSuccess: false,
    isError: false,
    message: "",
    data: [],
  },
  updateLocationStatus: {
    isLoading: false,
    isSuccess: false,
    isError: false,
    message: "",
    data: {},
  },
  updateLocation: {
    isLoading: false,
    isSuccess: false,
    isError: false,
    message: "",
    data: {},
  },
  newLocation: {
    isLoading: false,
    isSuccess: false,
    isError: false,
    message: "",
    data: {},
  },
};

/* locations */
const getAllLocationsRequest = (state, action) =>
  update(state, {
    locations: {
      isLoading: { $set: true },
      isSuccess: { $set: false },
      isError: { $set: false },
      message: { $set: "" },
    },
  });

const getAllLocationsSuccess = (state, action) =>
  update(state, {
    locations: {
      isLoading: { $set: false },
      isSuccess: { $set: true },
      isError: { $set: false },
      message: { $set: "" },
      data: { $set: action.payload },
    },
  });

const getAllLocationsError = (state, action) =>
  update(state, {
    locations: {
      isLoading: { $set: false },
      isSuccess: { $set: false },
      isError: { $set: true },
      message: { $set: action.payload },
    },
  });

/* location parkings */
const getLocationParkingsRequest = (state, action) =>
  update(state, {
    locationParkings: {
      isLoading: { $set: true },
      isSuccess: { $set: false },
      isError: { $set: false },
      message: { $set: "" },
    },
  });

const getLocationParkingsSuccess = (state, action) =>
  update(state, {
    locationParkings: {
      isLoading: { $set: false },
      isSuccess: { $set: true },
      isError: { $set: false },
      message: { $set: "" },
      data: { $set: action.payload },
    },
  });

const getLocationParkingsError = (state, action) =>
  update(state, {
    locationParkings: {
      isLoading: { $set: false },
      isSuccess: { $set: false },
      isError: { $set: true },
      message: { $set: action.payload },
    },
  });

/* location stats */
const getLocationStatsRequest = (state, action) =>
  update(state, {
    locationStats: {
      isLoading: { $set: true },
      isSuccess: { $set: false },
      isError: { $set: false },
      message: { $set: "" },
    },
  });

const getLocationStatsSuccess = (state, action) =>
  update(state, {
    locationStats: {
      isLoading: { $set: false },
      isSuccess: { $set: true },
      isError: { $set: false },
      message: { $set: "" },
      data: { $set: action.payload },
    },
  });

const getLocationStatsError = (state, action) =>
  update(state, {
    locationStats: {
      isLoading: { $set: false },
      isSuccess: { $set: false },
      isError: { $set: true },
      message: { $set: action.payload },
    },
  });

/* update location status */
const updateLocationStatusRequest = (state, action) =>
  update(state, {
    updateLocationStatus: {
      isLoading: { $set: true },
      isSuccess: { $set: false },
      isError: { $set: false },
      message: { $set: "" },
    },
  });

const updateLocationStatusSuccess = (state, action) =>
  update(state, {
    updateLocationStatus: {
      isLoading: { $set: false },
      isSuccess: { $set: true },
      isError: { $set: false },
      message: { $set: "Link status updated successfully!!" },
      data: { $set: action.payload },
    },
  });

const updateLocationStatusError = (state, action) =>
  update(state, {
    updateLocationStatus: {
      isLoading: { $set: false },
      isSuccess: { $set: false },
      isError: { $set: true },
      message: { $set: action.payload },
    },
  });

/* update location */
const updateLocationRequest = (state, action) =>
  update(state, {
    updateLocation: {
      isLoading: { $set: true },
      isSuccess: { $set: false },
      isError: { $set: false },
      message: { $set: "" },
    },
  });

const updateLocationSuccess = (state, action) =>
  update(state, {
    updateLocation: {
      isLoading: { $set: false },
      isSuccess: { $set: true },
      isError: { $set: false },
      message: { $set: "Link added successfully!!" },
      data: { $set: action.payload },
    },
  });

const updateLocationError = (state, action) =>
  update(state, {
    updateLocation: {
      isLoading: { $set: false },
      isSuccess: { $set: false },
      isError: { $set: true },
      message: { $set: action.payload },
    },
  });

/* new location */
const newLocationRequest = (state, action) =>
  update(state, {
    newLocation: {
      isLoading: { $set: true },
      isSuccess: { $set: false },
      isError: { $set: false },
      message: { $set: "" },
    },
  });

const newLocationSuccess = (state, action) =>
  update(state, {
    newLocation: {
      isLoading: { $set: false },
      isSuccess: { $set: true },
      isError: { $set: false },
      message: { $set: "Link added successfully!!" },
      data: { $set: action.payload },
    },
  });

const newLocationError = (state, action) =>
  update(state, {
    newLocation: {
      isLoading: { $set: false },
      isSuccess: { $set: false },
      isError: { $set: true },
      message: { $set: action.payload },
    },
  });

export default handleActions(
  {
    [constants.GET_ALL_LOCATIONS_REQUEST]: getAllLocationsRequest,
    [constants.GET_ALL_LOCATIONS_SUCCESS]: getAllLocationsSuccess,
    [constants.GET_ALL_LOCATIONS_ERROR]: getAllLocationsError,

    [constants.GET_LOCATION_PARKINGS_REQUEST]: getLocationParkingsRequest,
    [constants.GET_LOCATION_PARKINGS_SUCCESS]: getLocationParkingsSuccess,
    [constants.GET_LOCATION_PARKINGS_ERROR]: getLocationParkingsError,

    [constants.GET_LOCATION_STATS_REQUEST]: getLocationStatsRequest,
    [constants.GET_LOCATION_STATS_SUCCESS]: getLocationStatsSuccess,
    [constants.GET_LOCATION_STATS_ERROR]: getLocationStatsError,

    [constants.UPDATE_LOCATION_STATUS_REQUEST]: updateLocationStatusRequest,
    [constants.UPDATE_LOCATION_STATUS_SUCCESS]: updateLocationStatusSuccess,
    [constants.UPDATE_LOCATION_STATUS_ERROR]: updateLocationStatusError,

    [constants.UPDATE_LOCATION_REQUEST]: updateLocationRequest,
    [constants.UPDATE_LOCATION_SUCCESS]: updateLocationSuccess,
    [constants.UPDATE_LOCATION_ERROR]: updateLocationError,

    [constants.NEW_LOCATION_REQUEST]: newLocationRequest,
    [constants.NEW_LOCATION_SUCCESS]: newLocationSuccess,
    [constants.NEW_LOCATION_ERROR]: newLocationError,
  },
  initialState
);
