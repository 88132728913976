import React from "react";
import { Layout } from "antd";
import { InstagramOutlined, FacebookOutlined } from "@ant-design/icons";

import Auth from "./auth";
import shortshoutIcon from "../images/shortshout.ico";
const { Header, Content, Footer } = Layout;

class FooterMain extends React.Component {
  render() {
    return (
      <Footer
        style={{
          textAlign: "center",
          marginTop: "100px",
          background: "transparent",
          fontWeight: "bold",
        }}
      >
        <a href="mailto: parkinghunter@outlook.com">Contact Us</a>
        {/* &nbsp;&nbsp;&nbsp; &nbsp;
        <a href="https://parkinghunter.in" target="_BLANK">
          parkinghunter.in
        </a> */}
        <br />
        <a
          href="https://www.instagram.com/parkinghunter.in/"
          target="_BLANK"
          title="Instagram"
        >
          <InstagramOutlined className="footer-social-icons" />
        </a>
        <a
          href="https://facebook.com/parkinghunter.in"
          target="_BLANK"
          title="Facebook"
        >
          <FacebookOutlined className="footer-social-icons" />
        </a>
        <a
          href="https://shortshout.in/parkinghunter"
          target="_BLANK"
          title="shortshout"
          id="footer-shortshout"
        >
          <img src={shortshoutIcon} />
        </a>
      </Footer>
    );
  }
}

export default FooterMain;
