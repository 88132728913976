import * as actions from "../actions";
import { call, put } from "redux-saga/effects";
import { fireApi, showNotification } from "../../services";

export function* adminGetLatestUsersRequest(action) {
  const header = {};
  try {
    const response = yield call(
      fireApi,
      "POST",
      `/admin/getLatestUsersList`,
      action.payload,
      header
    );
    if (response) {
      yield put(actions.adminGetLatestUsersSuccess(response.data.data));
    }
  } catch (e) {
    console.log(e);
    yield put(actions.adminGetLatestUsersError(e.response.data));
  }
}

export function* adminGetLatestLinksRequest(action) {
  const header = {};
  try {
    const response = yield call(
      fireApi,
      "POST",
      `/admin/getLatestLinksList`,
      action.payload,
      header
    );
    if (response) {
      yield put(actions.adminGetLatestLinksSuccess(response.data.data));
    }
  } catch (e) {
    console.log(e);
    yield put(actions.adminGetLatestLinksError(e.response.data));
  }
}

export function* adminGetCompleteStatsRequest(action) {
  const header = {};
  try {
    const response = yield call(
      fireApi,
      "POST",
      `/admin/getCompleteStats`,
      action.payload,
      header
    );
    if (response) {
      yield put(actions.adminGetCompleteStatsSuccess(response.data.data));
    }
  } catch (e) {
    console.log(e);
    yield put(actions.adminGetCompleteStatsError(e.response.data));
  }
}

export function* adminSendDoVerificationEmailRequest(action) {
  const header = {};
  try {
    const response = yield call(
      fireApi,
      "POST",
      `/admin/sendDoVerificationEmailRequest`,
      action.payload,
      header
    );
    if (response) {
      yield put(
        actions.adminSendDoVerificationEmailSuccess(response.data.data)
      );
      yield put(actions.adminGetLatestUsersRequest());
      showNotification({
        type: "success",
        title: (response.data && response.data && response.data.message) || "",
      });
    }
  } catch (e) {
    console.log(e);
    yield put(actions.adminSendDoVerificationEmailError(e.response.data));
  }
}

/* new */
export function* adminNewLocationRequest(action) {
  const header = {};
  try {
    const response = yield call(
      fireApi,
      "POST",
      `/admin/addNewLocation`,
      action.payload,
      header
    );
    if (response) {
      let { data } = response;
      if (data.error) {
        yield put(actions.adminNewLocationError(data));
        showNotification({
          type: "warning",
          title: data.message,
        });
      } else {
        yield put(actions.adminNewLocationSuccess(response.data));
        showNotification({
          type: "success",
          title: data.message,
        });
        yield put(actions.getAllLocationsRequest());
      }
    }
  } catch (e) {
    yield put(actions.adminNewLocationError(e.response.data));
    showNotification({
      type: "error",
      title: "Add new location Failed!!",
      message: e.response.data.message,
    });
  }
}

export function* adminGetUsersRequest(action) {
  const header = {};
  try {
    const response = yield call(
      fireApi,
      "POST",
      `/admin/getUsersList`,
      action.payload,
      header
    );
    if (response) {
      yield put(actions.adminGetUsersSuccess(response.data.data));
    }
  } catch (e) {
    console.log(e);
    yield put(actions.adminGetUsersError(e.response.data));
  }
}

export function* adminGetParkingsRequest(action) {
  const header = {};
  try {
    const response = yield call(
      fireApi,
      "POST",
      `/admin/getParkingsList`,
      action.payload,
      header
    );
    if (response) {
      yield put(actions.adminGetParkingsSuccess(response.data.data));
    }
  } catch (e) {
    console.log(e);
    yield put(actions.adminGetParkingsError(e.response.data));
  }
}

export function* adminGetLocationsSuggestionRequest(action) {
  const header = {};
  try {
    const response = yield call(
      fireApi,
      "POST",
      `/admin/getLocationsSuggestionList`,
      action.payload,
      header
    );
    if (response) {
      yield put(actions.adminGetLocationsSuggestionSuccess(response.data.data));
    }
  } catch (e) {
    console.log(e);
    yield put(actions.adminGetLocationsSuggestionError(e.response.data));
  }
}
