const constants = {
  GET_ALL_LOCATIONS_REQUEST: "GET_ALL_LOCATIONS_REQUEST",
  GET_ALL_LOCATIONS_SUCCESS: "GET_ALL_LOCATIONS_SUCCESS",
  GET_ALL_LOCATIONS_ERROR: "GET_ALL_LOCATIONS_ERROR",

  NEW_PARKING_REQUEST: "NEW_PARKING_REQUEST",
  NEW_PARKING_SUCCESS: "NEW_PARKING_SUCCESS",
  NEW_PARKING_ERROR: "NEW_PARKING_ERROR",

  MY_PARKINGS_REQUEST: "MY_PARKINGS_REQUEST",
  MY_PARKINGS_SUCCESS: "MY_PARKINGS_SUCCESS",
  MY_PARKINGS_ERROR: "MY_PARKINGS_ERROR",

  DELETE_PARKING_REQUEST: "DELETE_PARKING_REQUEST",
  DELETE_PARKING_SUCCESS: "DELETE_PARKING_SUCCESS",
  DELETE_PARKING_ERROR: "DELETE_PARKING_ERROR",

  UPDATE_PARKING_STATUS_REQUEST: "UPDATE_PARKING_STATUS_REQUEST",
  UPDATE_PARKING_STATUS_SUCCESS: "UPDATE_PARKING_STATUS_SUCCESS",
  UPDATE_PARKING_STATUS_ERROR: "UPDATE_PARKING_STATUS_ERROR",

  UPDATE_PARKING_REQUEST: "UPDATE_PARKING_REQUEST",
  UPDATE_PARKING_SUCCESS: "UPDATE_PARKING_SUCCESS",
  UPDATE_PARKING_ERROR: "UPDATE_PARKING_ERROR",

  GET_LOCATION_PARKINGS_REQUEST: "GET_LOCATION_PARKINGS_REQUEST",
  GET_LOCATION_PARKINGS_SUCCESS: "GET_LOCATION_PARKINGS_SUCCESS",
  GET_LOCATION_PARKINGS_ERROR: "GET_LOCATION_PARKINGS_ERROR",

  GET_LOCATION_STATS_REQUEST: "GET_LOCATION_STATS_REQUEST",
  GET_LOCATION_STATS_SUCCESS: "GET_LOCATION_STATS_SUCCESS",
  GET_LOCATION_STATS_ERROR: "GET_LOCATION_STATS_ERROR",

  ADMIN_NEW_LOCATION_REQUEST: "ADMIN_NEW_LOCATION_REQUEST",
  ADMIN_NEW_LOCATION_SUCCESS: "ADMIN_NEW_LOCATION_SUCCESS",
  ADMIN_NEW_LOCATION_ERROR: "ADMIN_NEW_LOCATION_ERROR",

  GET_MY_PROFILE_REQUEST: "GET_MY_PROFILE_REQUEST",
  GET_MY_PROFILE_SUCCESS: "GET_MY_PROFILE_SUCCESS",
  GET_MY_PROFILE_ERROR: "GET_MY_PROFILE_ERROR",

  UPDATE_MY_PROFILE_REQUEST: "UPDATE_MY_PROFILE_REQUEST",
  UPDATE_MY_PROFILE_SUCCESS: "UPDATE_MY_PROFILE_SUCCESS",
  UPDATE_MY_PROFILE_ERROR: "UPDATE_MY_PROFILE_ERROR",

  UPDATE_PASSWORD_REQUEST: "UPDATE_PASSWORD_REQUEST",
  UPDATE_PASSWORD_SUCCESS: "UPDATE_PASSWORD_SUCCESS",
  UPDATE_PASSWORD_ERROR: "UPDATE_PASSWORD_ERROR",

  UPDATE_LOCATION_STATUS_REQUEST: "UPDATE_LOCATION_STATUS_REQUEST",
  UPDATE_LOCATION_STATUS_SUCCESS: "UPDATE_LOCATION_STATUS_SUCCESS",
  UPDATE_LOCATION_STATUS_ERROR: "UPDATE_LOCATION_STATUS_ERROR",

  UPDATE_LOCATION_REQUEST: "UPDATE_LOCATION_REQUEST",
  UPDATE_LOCATION_SUCCESS: "UPDATE_LOCATION_SUCCESS",
  UPDATE_LOCATION_ERROR: "UPDATE_LOCATION_ERROR",

  ADMIN_GET_USERS_REQUEST: "ADMIN_GET_USERS_REQUEST",
  ADMIN_GET_USERS_SUCCESS: "ADMIN_GET_USERS_SUCCESS",
  ADMIN_GET_USERS_ERROR: "ADMIN_GET_USERS_ERROR",

  ADMIN_GET_PARKINGS_REQUEST: "ADMIN_GET_PARKINGS_REQUEST",
  ADMIN_GET_PARKINGS_SUCCESS: "ADMIN_GET_PARKINGS_SUCCESS",
  ADMIN_GET_PARKINGS_ERROR: "ADMIN_GET_PARKINGS_ERROR",

  ADMIN_GET_LOCATIONS_SUGGESTION_REQUEST:
    "ADMIN_GET_LOCATIONS_SUGGESTION_REQUEST",
  ADMIN_GET_LOCATIONS_SUGGESTION_SUCCESS:
    "ADMIN_GET_LOCATIONS_SUGGESTION_SUCCESS",
  ADMIN_GET_LOCATIONS_SUGGESTION_ERROR: "ADMIN_GET_LOCATIONS_SUGGESTION_ERROR",

  /* old */

  REGISTER_REQUEST: "REGISTER_REQUEST",
  REGISTER_SUCCESS: "REGISTER_SUCCESS",
  REGISTER_ERROR: "REGISTER_ERROR",

  LOGIN_REQUEST: "LOGIN_REQUEST",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_ERROR: "LOGIN_ERROR",

  IS_LOGGEDIN_USER: "IS_LOGGEDIN_USER",

  LOGOUT_REQUEST: "LOGOUT_REQUEST",
  LOGOUT_REQUEST_SUCCESS: "LOGOUT_REQUEST_SUCCESS",
  LOGOUT_REQUEST_ERROR: "LOGOUT_REQUEST_ERROR",

  // TODO: remove below lines and realted code when new implement is done
  VERIFY_EMAIL_REQUEST: "VERIFY_EMAIL_REQUEST",
  VERIFY_EMAIL_SUCCESS: "VERIFY_EMAIL_SUCCESS",
  VERIFY_EMAIL_ERROR: "VERIFY_EMAIL_ERROR",

  // this is used for new resend verification link
  SEND_EMAIL_VERIFY_CODE_REQUEST: "SEND_EMAIL_VERIFY_CODE_REQUEST",
  SEND_EMAIL_VERIFY_CODE_SUCCESS: "SEND_EMAIL_VERIFY_CODE_SUCCESS",
  SEND_EMAIL_VERIFY_CODE_ERROR: "SEND_EMAIL_VERIFY_CODE_ERROR",

  NEW_LINK_REQUEST: "NEW_LINK_REQUEST",
  NEW_LINK_SUCCESS: "NEW_LINK_SUCCESS",
  NEW_LINK_ERROR: "NEW_LINK_ERROR",

  UPDATE_LINK_REQUEST: "UPDATE_LINK_REQUEST",
  UPDATE_LINK_SUCCESS: "UPDATE_LINK_SUCCESS",
  UPDATE_LINK_ERROR: "UPDATE_LINK_ERROR",

  MY_LINKS_REQUEST: "MY_LINKS_REQUEST",
  MY_LINKS_SUCCESS: "MY_LINKS_SUCCESS",
  MY_LINKS_ERROR: "MY_LINKS_ERROR",

  GET_PUBLIC_SHORT_SHOUT_REQUEST: "GET_PUBLIC_SHORT_SHOUT_REQUEST",
  GET_PUBLIC_SHORT_SHOUT_SUCCESS: "GET_PUBLIC_SHORT_SHOUT_SUCCESS",
  GET_PUBLIC_SHORT_SHOUT_ERROR: "GET_PUBLIC_SHORT_SHOUT_ERROR",

  DELETE_LINK_REQUEST: "DELETE_LINK_REQUEST",
  DELETE_LINK_SUCCESS: "DELETE_LINK_SUCCESS",
  DELETE_LINK_ERROR: "DELETE_LINK_ERROR",

  RESET_PASSWORD_REQUEST: "RESET_PASSWORD_REQUEST",
  RESET_PASSWORD_SUCCESS: "RESET_PASSWORD_SUCCESS",
  RESET_PASSWORD_ERROR: "RESET_PASSWORD_ERROR",

  NEW_LOCATION_REQUEST: "NEW_LOCATION_REQUEST",
  NEW_LOCATION_SUCCESS: "NEW_LOCATION_SUCCESS",
  NEW_LOCATION_ERROR: "NEW_LOCATION_ERROR",

  UPDATE_LINK_CLICK_COUNT_REQUEST: "UPDATE_LINK_CLICK_COUNT_REQUEST",
  UPDATE_LINK_CLICK_COUNT_SUCCESS: "UPDATE_LINK_CLICK_COUNT_SUCCESS",
  UPDATE_LINK_CLICK_COUNT_ERROR: "UPDATE_LINK_CLICK_COUNT_ERROR",

  UPDATE_LINK_STATUS_REQUEST: "UPDATE_LINK_STATUS_REQUEST",
  UPDATE_LINK_STATUS_SUCCESS: "UPDATE_LINK_STATUS_SUCCESS",
  UPDATE_LINK_STATUS_ERROR: "UPDATE_LINK_STATUS_ERROR",

  GET_RECENTLY_ADDED_USERS_REQUEST: "GET_RECENTLY_ADDED_USERS_REQUEST",
  GET_RECENTLY_ADDED_USERS_SUCCESS: "GET_RECENTLY_ADDED_USERS_SUCCESS",
  GET_RECENTLY_ADDED_USERS_ERROR: "GET_RECENTLY_ADDED_USERS_ERROR",

  MY_STATS_REQUEST: "MY_STATS_REQUEST",
  MY_STATS_SUCCESS: "MY_STATS_SUCCESS",
  MY_STATS_ERROR: "MY_STATS_ERROR",

  ADMIN_GET_LATEST_USERS_REQUEST: "ADMIN_GET_LATEST_USERS_REQUEST",
  ADMIN_GET_LATEST_USERS_SUCCESS: "ADMIN_GET_LATEST_USERS_SUCCESS",
  ADMIN_GET_LATEST_USERS_ERROR: "ADMIN_GET_LATEST_USERS_ERROR",

  ADMIN_GET_LATEST_LINKS_REQUEST: "ADMIN_GET_LATEST_LINKS_REQUEST",
  ADMIN_GET_LATEST_LINKS_SUCCESS: "ADMIN_GET_LATEST_LINKS_SUCCESS",
  ADMIN_GET_LATEST_LINKS_ERROR: "ADMIN_GET_LATEST_LINKS_ERROR",

  ADMIN_GET_COMPLETE_STATS_REQUEST: "ADMIN_GET_COMPLETE_STATS_REQUEST",
  ADMIN_GET_COMPLETE_STATS_SUCCESS: "ADMIN_GET_COMPLETE_STATS_SUCCESS",
  ADMIN_GET_COMPLETE_STATS_ERROR: "ADMIN_GET_COMPLETE_STATS_ERROR",

  ADMIN_SEND_DO_VERIFICATION_EMAIL_REQUEST:
    "ADMIN_SEND_DO_VERIFICATION_EMAIL_REQUEST",
  ADMIN_SEND_DO_VERIFICATION_EMAIL_SUCCESS:
    "ADMIN_SEND_DO_VERIFICATION_EMAIL_SUCCESS",
  ADMIN_SEND_DO_VERIFICATION_EMAIL_ERROR:
    "ADMIN_SEND_DO_VERIFICATION_EMAIL_ERROR",
};

export default constants;
