import * as actions from "../actions";
import { call, put } from "redux-saga/effects";
import {
  fireApi,
  showNotification,
  setLoggedInSession,
  getLoggedUser,
  clearLoggedInSession,
  tempVariableToHold_isEmailVerified,
} from "../../services";
import { notification } from "antd";

export function* registerRequest(action) {
  const header = {};
  try {
    const response = yield call(
      fireApi,
      "POST",
      `/auth/register`,
      action.payload,
      header
    );
    if (response) {
      yield put(actions.registerSuccess(response.data));
      showNotification({
        type: "success",
        title: "Successfully Registered!! You can login now!!",
      });
    }
  } catch (e) {
    yield put(actions.registerError(e.response.data));
    showNotification({
      type: "error",
      title: "Registration Failed!!",
      message: e.response.data.message,
    });
  }
}

export function* loginRequest(action) {
  let emailPwd = {
    email: action.payload.email || "",
    password: action.payload.password || "",
  };
  // let encodedUserPwd = btoa(JSON.stringify(emailPwd));
  const header = {
    // withCredentials: true,
    "Cache-Control": "no-cache",
    // "auth-login-secure-header": encodedUserPwd,
  };
  try {
    const response = yield call(
      fireApi,
      "POST",
      `/auth/login`,
      action.payload,
      header
    );
    const { data } = response;
    if (data.error) {
      yield put(actions.loginError(response.data));
      showNotification({
        type: "error",
        title: "Login Failed!!",
        message: data.message,
      });
    } else {
      setLoggedInSession(response.data.data.token);
      let userInfo = getLoggedUser();
      yield put(actions.loginSuccess(userInfo));
    }
  } catch (e) {
    yield put(actions.loginError(e.response.data));
    showNotification({
      type: "error",
      title: "Login Failed!!",
      message: e.response.data.message,
    });
  }
}

export function* isLoggedInUser(action) {
  let userInfo = getLoggedUser();
  if (userInfo && userInfo.token) {
    yield put(actions.loginSuccess(userInfo));
  }
  // else {
  //   yield put(actions.logoutSuccess());
  // }
}

export function* logoutRequest(action) {
  clearLoggedInSession();
  const header = {};
  try {
    const response = yield call(
      fireApi,
      "POST",
      `/auth/logout`,
      action.payload,
      header
    );
    yield put(actions.logoutRequestSuccess());
  } catch (e) {
    yield put(actions.logoutRequestError());
  }
  clearLoggedInSession();
}

export function* resetPasswordRequest(action) {
  const header = {
    // withCredentials: true,
    "Cache-Control": "no-cache",
  };
  try {
    const response = yield call(
      fireApi,
      "POST",
      `/auth/resetPassword`,
      action.payload,
      header
    );
    if (response) {
      yield put(actions.resetPasswordSuccess(response.data));
      showNotification({
        type: "success",
        title: "Reset Password Success!!",
        message: response.data.message,
      });
    }
  } catch (e) {
    yield put(actions.resetPasswordError(e.response));
    showNotification({
      type: "error",
      title: "Reset Password Failed!!",
      message: e.response.data.message,
    });
  }
}

export function* verifyEmailRequest(action) {
  const header = {
    // withCredentials: true,
    "Cache-Control": "no-cache",
  };
  try {
    const response = yield call(
      fireApi,
      "POST",
      `/auth/verifyEmail`,
      action.payload,
      header
    );
    if (response) {
      tempVariableToHold_isEmailVerified(true);
      yield put(actions.verifyEmailSuccess(response.data));
      showNotification({
        type: "success",
        title: "Email Verified Successfully!!",
        message: response.data.message,
      });
    }
  } catch (e) {
    yield put(actions.verifyEmailError(e.response));
    showNotification({
      type: "error",
      title: "Email Verified Failed!!",
      message: e.response.data.message,
    });
  }
}

export function* sendEmailVerifyCodeRequest(action) {
  const header = {
    // withCredentials: true,
    "Cache-Control": "no-cache",
  };
  try {
    const response = yield call(
      fireApi,
      "POST",
      `/auth/sendEmailVerifyCode`,
      action.payload,
      header
    );
    console.log(response);
    console.log(response);
    console.log(response);
    console.log(response);
    const { data } = response;
    if (data.error) {
      yield put(actions.sendEmailVerifyCodeError(response.data));
      showNotification({
        type: "error",
        title: "Failed to send verification email!!",
        message: response.data.message,
      });
    } else {
      yield put(actions.sendEmailVerifyCodeSuccess(response.data));
      showNotification({
        type: "success",
        title: "Verification code sent. Check your email for code!!",
        message: response.data.message,
      });
    }
  } catch (e) {
    yield put(actions.sendEmailVerifyCodeError(e.response));
    showNotification({
      type: "error",
      title: "Failed to sent verification code!!",
      message: e.response.data.message,
    });
  }
}

export function* getMyProfileRequest(action) {
  const header = {};
  try {
    const response = yield call(
      fireApi,
      "POST",
      `/auth/getMyProfile`,
      {},
      header
    );
    if (response) {
      yield put(actions.getMyProfileSuccess(response.data.data));
    }
  } catch (e) {
    yield put(actions.getMyProfileError(e.response.data));
  }
}

export function* updateMyProfileRequest(action) {
  const header = {};
  try {
    const response = yield call(
      fireApi,
      "POST",
      `/auth/updateMyProfile`,
      action.payload,
      header
    );
    if (response) {
      showNotification({
        type: "success",
        title: response.data.message,
        message: response.data.message,
      });
      yield put(actions.updateMyProfileSuccess(response.data.data));
    }
  } catch (e) {
    showNotification({
      type: "error",
      title: e.response.data.message,
      message: e.response.data.message,
    });
    yield put(actions.updateMyProfileError(e.response.data));
  }
}

export function* updatePasswordRequest(action) {
  console.log(action);
  let tempData = {
    oldPassword: action.payload.oldPassword || "",
    newPassword: action.payload.newPassword || "",
    confirmPassword: action.payload.confirmPassword || "",
  };
  let encodedData = btoa(JSON.stringify(tempData));
  const header = {
    // withCredentials: true,
    "Cache-Control": "no-cache",
    "auth-update-password-secure-header": encodedData,
  };
  try {
    const response = yield call(
      fireApi,
      "POST",
      `/auth/updatePassword`,
      {},
      header
    );
    if (response) {
      yield put(actions.updatePasswordSuccess(response.data));
      showNotification({
        type: "success",
        title: "Password updated successfully!!",
        message: response.data.message,
      });
    }
  } catch (e) {
    yield put(actions.updatePasswordError(e.response.data));
    showNotification({
      type: "error",
      title: "Failed to update password!!",
      message: e.response.data.message,
    });
  }
}
