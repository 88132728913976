import React from "react";
import { Row, Col } from "antd";

class HowItWorks extends React.Component {
  render() {
    return (
      <div id="landing-how-it-works">
        <Row>
          <Col className="" xl={24} md={24} xs={24}>
            <h1>How it Works!</h1>
          </Col>
        </Row>
        <Row>
          <Col className="steps" xl={8} md={8} xs={24}>
            <div className="how-it-work-steps">
              <b> STEP - 1</b>
              <br /> Person A in Society XYZ has a basement car parking near
              tower B which is very far from his tower P.
              <br />
              <br /> Person A list his parking space on Parkinghunter.in as
              available for Exchange.
            </div>
          </Col>
          <Col className="" xl={8} md={8} xs={24}>
            <div className="how-it-work-steps">
              <b> STEP - 2</b>
              <br />
              Person B in same Society XYZ has a basement car parking near tower
              P which is very far from his tower B.
              <br />
              <br /> Person B visit Parkinghunter.in and look for existing list
              of parking space in same Society. Luckily he found parking space
              posted by person A.
            </div>
          </Col>
          <Col className="" xl={8} md={8} xs={24}>
            <div className="how-it-work-steps">
              <b> STEP - 3</b>
              <br />
              Person B sends a contact request to Person A for more details.
              <br />
              <br />
              They meet and with mututal consent or officially they Exchange
              their car parking space.
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

export default HowItWorks;
