import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import * as actions from "../../redux/actions";

export default (ComposedComponent) => {
  class Authentication extends Component {
    constructor(props) {
      super(props);
      this._checkAndRedirect();
    }

    _checkAndRedirect = () => {
      const { loggedUserData } = this.props;
      if (loggedUserData === false) {
        this.props.history.replace("/");
      }
    };

    componentDidUpdate(prevProps) {
      this._checkAndRedirect();
    }

    render() {
      return <ComposedComponent {...this.props} />;
    }
  }

  const mapStateToProps = (state) => ({
    loggedUserData: state.auth.login.data || false,
  });

  const mapDispatchToProps = (dispatch) => ({
    isLoggedInUser: (data) => dispatch(actions.isLoggedInUser(data)),
  });

  return withRouter(
    connect(mapStateToProps, mapDispatchToProps)(Authentication)
  );
};
