import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Card, Avatar, Popconfirm, Popover, Button } from "antd";

import * as actions from "../../redux/actions";

const { Meta } = Card;

class ParkingBlock extends React.Component {
  constructor(props) {
    super(props);
    this.props.isLoggedInUser();
  }

  _onDeleteParking = () => {
    this.props.doDeleteParking(this.props.data);
  };

  _onEditParking = (e) => {
    e.preventDefault();
    this.props.onEditParking(this.props.data);
  };

  _changeParkingStatus = (newStatus) => {
    this.props.doParkingStatusChange({
      newStatus: newStatus,
      parkingData: this.props.data,
    });
  };

  _renderContactDetailsBlock = () => {
    let { loggedUserData, data } = this.props;
    let contactContent = "";
    if (!loggedUserData) {
      contactContent = () => {
        return <>Sign In for contact details!</>;
      };
    } else {
      const { user } = data;
      contactContent = () => {
        return (
          <>
            Name: {user.name}
            <br />
            Mobile: {user.mobileNumber}
            <br />
            Email: {user.email}
          </>
        );
      };
    }
    return (
      <Popover content={contactContent} trigger="click">
        <Button>Contact Details</Button>
      </Popover>
    );
  };

  render() {
    const { data } = this.props;
    const { location } = data;
    // let avtarText = "";
    let title = "";
    let avtarText =
      (data.availableFor && data.availableFor.charAt(0).toUpperCase()) || "";

    // let title =
    //   location.locationName +
    //   " " +
    //   location.locationCity +
    //   " " +
    //   location.locationState;

    let clickCount = 0;
    if (this.props.data && this.props.data.count_click) {
      clickCount = this.props.data.count_click;
    }
    let parkingIsActive = false;
    if (data.status === "active") {
      parkingIsActive = true;
    }

    let url = this.props.data.url;
    let prefix = "http://";
    let prefix2 = "https://";

    return (
      <Card
        className="parking-listing-block"
        style={{
          marginBottom: "20px",
        }}
        actions={[<div>{this._renderContactDetailsBlock()}</div>]}
      >
        <Meta
          // avatar={
          //   <Avatar
          //     style={{
          //       color: "white",
          //       backgroundColor: "#1890FF",
          //       fontWeight: "bold",
          //       fontSize: "26px",
          //     }}
          //   >
          //     {avtarText}
          //   </Avatar>
          // }
          title={title}
          description={
            <>
              <table cellPadding="5">
                <thead></thead>
                <tbody>
                  {/* <tr>
                    <th colSpan="2">Parking Details</th>
                  </tr> */}
                  <tr>
                    <td>For Vehicle:</td>
                    <th>{data.forVehicle}</th>
                  </tr>
                  <tr>
                    <td>Available For:</td>
                    <th>{data.availableFor}</th>
                  </tr>
                  <tr>
                    <td>Parking Type:</td>
                    <th>{data.parkingType}</th>
                  </tr>
                  {data.description && data.description !== "" ? (
                    <tr>
                      <td>Description:</td>
                      <th>{data.description}</th>
                    </tr>
                  ) : null}
                  {data.comment && data.comment !== "" ? (
                    <tr>
                      <td>Comment:</td>
                      <th>{data.comment}</th>
                    </tr>
                  ) : null}
                </tbody>
              </table>
            </>
          }
        />
      </Card>
    );
  }
}

const mapStateToProps = (state) => ({
  loggedUserData: state.auth.login.data || false,
});

const mapDispatchToProps = (dispatch) => ({
  isLoggedInUser: (data) => dispatch(actions.isLoggedInUser(data)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ParkingBlock)
);
