import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import { Button, Row, Col } from "antd";
import SideDrawer from "../SideDrawer";
// import FormEditParking from "../forms/FormEditParking";
import * as actions from "../../redux/actions";
// import MyStats from "./MyStats";
// import ManageLink from "./ManageLink";
// import ManageParking from "./ManageParking";
import ShortShout from "../shortshout";

import { get_tempVariableToHold_isEmailVerified } from "../../services";
import Search from "antd/lib/transfer/search";

import HowItWorks from "./HowItWorks";
import SearchBox from "./SearchBox";

class Landing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openNewParkingForm: false,
      openEditParkingForm: false,
      currentEditParkingData: false,
    };
  }

  componentDidMount() {
    if (this.props.loggedUserData) {
      this.props.myParkingsRequest();
      // this.props.myStatsRequest();
    }
  }

  _openNewShortShout = () => {
    this.setState({
      openNewParkingForm: true,
    });
  };

  _onDeleteParking = (data) => {
    this.props.deleteParkingRequest(data);
  };

  _onEditParking = (data) => {
    this.setState({
      openEditParkingForm: true,
      currentEditParkingData: data,
    });
  };

  _doParkingStatusChange = (data) => {
    this.props.updateParkingStatusRequest({
      _id: data.parkingData._id,
      status: data.newStatus === true ? "active" : "inactive",
    });
  };

  _renderLandingInformation = () => {
    return (
      <Row id="landing-information">
        <Col className="" xl={24} md={24} xs={24}>
          <h1 className="landing-heading-1">Find Vehicle Parking Space</h1>
          <div className="landing-heading-2">
            Search parking space for Rent, Exchange, Sale in your Society
          </div>
          <div className="landing-heading-3">
            List your parking space for Rent, Exchange, Sale
          </div>
        </Col>
      </Row>
    );
  };

  render() {
    // let { myLinks } = this.props;
    let { loggedUserData, myParkings } = this.props;

    // let myShortShoutUrl =
    //   process.env.REACT_APP_WEB_BASE_URL + "/" + loggedUserData.username;
    // let linksToShow = myLinks.filter((link) => link.status === "active");

    let isEmailVerified =
      (loggedUserData && loggedUserData.isEmailVerified) || false;
    if (isEmailVerified === false) {
      isEmailVerified = get_tempVariableToHold_isEmailVerified();
    }

    return (
      <>
        <div className="landing-first-fit-view">
          {/* <div className="bg"></div> */}
          {this._renderLandingInformation()}
          <SearchBox />
        </div>
        <HowItWorks />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  myParkings: state.parkings.myParkings.data || [],

  // newLink: state.links.newLink || false,
  loggedUserData: state.auth.login.data || false,
  // links: state.links,
  // myLinks: state.links.myLinks.data || [],
  // myStats: state.dashboard.myStats,
  // updateLink: state.links.updateLink || false,
});

const mapDispatchToProps = (dispatch) => ({
  myParkingsRequest: (data) => dispatch(actions.myParkingsRequest(data)),
  deleteParkingRequest: (data) => dispatch(actions.deleteParkingRequest(data)),
  updateParkingStatusRequest: (data) =>
    dispatch(actions.updateParkingStatusRequest(data)),

  // newLinkRequest: (data) => dispatch(actions.newLinkRequest(data)),
  // updateLinkRequest: (data) => dispatch(actions.updateLinkRequest(data)),
  // myLinksRequest: (data) => dispatch(actions.myLinksRequest(data)),
  // deleteLinkRequest: (data) => dispatch(actions.deleteLinkRequest(data)),
  // updateLinkStatusRequest: (data) =>
  //   dispatch(actions.updateLinkStatusRequest(data)),
  // myStatsRequest: (data) => dispatch(actions.myStatsRequest(data)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Landing)
);
