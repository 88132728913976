import React from "react";
import { Form, Input, Button, Spin } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";

import * as actions from "../../redux/actions";

import AuthGoogle from "./AuthGoogle";

class FormLogin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  onFinish = (values) => {
    this.props.doLogin(values);
  };
  onFinishForgotPassword = (values) => {
    this.props.doResetPassword(values);
  };
  _doLogin = (data) => {
    this.props.loginRequest(data);
  };
  render() {
    let { isLoading } = this.props.login;
    return (
      <div id="form-login">
        <div className="heading-1">Sign In</div>

        <Form
          name="normal_login"
          className="login-form"
          initialValues={{
            remember: true,
          }}
          onFinish={this._doLogin}
        >
          <Form.Item
            name="email"
            rules={[
              {
                type: "email",
                message: "The input is not valid E-mail!",
              },
              {
                required: true,
                message: "Please input your E-mail!",
              },
            ]}
          >
            <Input
              prefix={<UserOutlined className="site-form-item-icon" />}
              placeholder="Email"
            />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: "Please input your Password!",
              },
            ]}
          >
            <Input
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              placeholder="Password"
            />
          </Form.Item>
          <Form.Item>
            {isLoading ? (
              <Spin />
            ) : (
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
              >
                Sign In
              </Button>
            )}
          </Form.Item>
          <Form.Item>
            <Link to="/forgotPassword">Forgot password?</Link>
            <br />
            <Link to="/resendVerificationLink">Re-send verification link</Link>
          </Form.Item>
          <AuthGoogle />
        </Form>
      </div>
    );
  }
}

// export default LoginForm;

const mapStateToProps = (state) => ({
  login: state.auth.login,
  register: state.auth.register,
  resetPassword: state.auth.resetPassword,
  loggedUserData: state.auth.login.data || false,
});

const mapDispatchToProps = (dispatch) => ({
  registerRequest: (data) => dispatch(actions.registerRequest(data)),
  loginRequest: (data) => dispatch(actions.loginRequest(data)),
  isLoggedInUser: (data) => dispatch(actions.isLoggedInUser(data)),
  logoutRequest: (data) => dispatch(actions.logoutRequest(data)),
  resetPasswordRequest: (data) => dispatch(actions.resetPasswordRequest(data)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(FormLogin)
);
