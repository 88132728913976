import * as actions from "../actions";
import { call, put } from "redux-saga/effects";
import { fireApi, showNotification } from "../../services";

export function* newParkingRequest(action) {
  const header = {};
  try {
    const response = yield call(
      fireApi,
      "POST",
      `/parkings/addNewParking`,
      action.payload,
      header
    );
    if (response) {
      let { data } = response;
      if (data.error) {
        yield put(actions.newParkingError(data));
        showNotification({
          type: "warning",
          title: data.message,
        });
      } else {
        yield put(actions.newParkingSuccess(response.data));
        showNotification({
          type: "success",
          title: "Parking added successfully!!",
        });
        yield put(actions.myParkingsRequest());
      }
    }
  } catch (e) {
    yield put(actions.newParkingError(e.response.data));
    showNotification({
      type: "error",
      title: "Add new parking Failed!!",
      message: e.response.data.message,
    });
  }
}

export function* myParkingsRequest(action) {
  const header = {};
  try {
    const response = yield call(
      fireApi,
      "POST",
      `/parkings/getMyParkings`,
      {},
      header
    );
    if (response) {
      yield put(actions.myParkingsSuccess(response.data.data));
    }
  } catch (e) {
    yield put(actions.myParkingsError(e.response.data));
  }
}

export function* deleteParkingRequest(action) {
  const header = {};
  try {
    const response = yield call(
      fireApi,
      "POST",
      `/parkings/deleteParking`,
      action.payload,
      header
    );
    if (response) {
      yield put(actions.deleteParkingSuccess(response.data));
      showNotification({
        type: "success",
        title: "Parking deleted successfully!!",
      });
      yield put(actions.myParkingsRequest());
    }
  } catch (e) {
    yield put(actions.deleteParkingError(e.response.data));
    showNotification({
      type: "error",
      title: "Delete parking failed!!",
      message: e.response.data.message,
    });
  }
}

export function* updateParkingStatusRequest(action) {
  const header = {};
  try {
    const response = yield call(
      fireApi,
      "POST",
      `/parkings/updateParkingStatus`,
      action.payload,
      header
    );
    if (response) {
      yield put(actions.updateParkingStatusSuccess(response.data));
      yield put(actions.myParkingsRequest());
    }
  } catch (e) {
    yield put(actions.updateParkingStatusError(e.response.data));
  }
}

export function* updateParkingRequest(action) {
  const header = {};
  try {
    const response = yield call(
      fireApi,
      "POST",
      `/parkings/updateParking`,
      action.payload,
      header
    );
    if (response) {
      let { data } = response;
      if (data.error) {
        yield put(actions.updateParkingError(data));
        showNotification({
          type: "warning",
          title: data.message,
        });
      } else {
        yield put(actions.updateParkingSuccess(response.data));
        showNotification({
          type: "success",
          title: "Parking updated successfully!!",
        });
        yield put(actions.myParkingsRequest());
      }
    }
  } catch (e) {
    yield put(actions.updateParkingError(e.response.data));
    showNotification({
      type: "error",
      title: "Parking update Failed!!",
      message: e.response.data.message,
    });
  }
}
