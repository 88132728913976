import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import { Button, Row, Col, Tabs } from "antd";
import SideDrawer from "../SideDrawer";
// import FormAddNewShortShout from "../forms/FormAddNewShortShout";
import * as actions from "../../redux/actions";
// import MyStats from "./MyStats";
import UsersList from "./UsersList";
import LinksList from "./LinksList";
import AdminStats from "./AdminStats";
// import ShortShout from "../shortshout";
// import FormEmailVerification from "../forms/FormEmailVerification";

import { get_tempVariableToHold_isEmailVerified } from "../../services";

const { TabPane } = Tabs;

class Admin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    if (this.props.loggedUserData) {
      // this.props.myLinksRequest();
      // this.props.myStatsRequest();
      this.props.adminGetLatestUsersRequest();
      this.props.adminGetLatestLinksRequest();
      this.props.adminGetCompleteStatsRequest();
    }
  }

  render() {
    // console.log("------this.props");
    // console.log(this.props);
    const { admin } = this.props;
    const { usersList, linksList, stats } = admin;
    return (
      <>
        <AdminStats stats={stats.data} />

        {/* <Row>
          <Col md={24} xl={24} sm={24} xs={24} style={{ padding: "10px" }}>
            <Tabs
              onChange={(tabKey) => {
                // console.log(tabKey);
              }}
              type="card"
            >
              <TabPane tab="Latest Users" key="1">
                <UsersList
                  usersList={usersList.data}
                  adminSendDoVerificationEmailRequest={
                    this.props.adminSendDoVerificationEmailRequest
                  }
                />
              </TabPane>
              <TabPane tab="Latest Links" key="2">
                <LinksList linksList={linksList.data} />
              </TabPane>
            </Tabs>
          </Col>
        </Row> */}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  // newLink: state.links.newLink || false,
  loggedUserData: state.auth.login.data || false,
  admin: state.admin,
  // myLinks: state.links.myLinks.data || [],
  // myStats: state.dashboard.myStats,
  // updateLink: state.links.updateLink || false,
});

const mapDispatchToProps = (dispatch) => ({
  adminGetLatestUsersRequest: (data) =>
    dispatch(actions.adminGetLatestUsersRequest(data)),
  adminGetLatestLinksRequest: (data) =>
    dispatch(actions.adminGetLatestLinksRequest(data)),
  adminGetCompleteStatsRequest: (data) =>
    dispatch(actions.adminGetCompleteStatsRequest(data)),
  adminSendDoVerificationEmailRequest: (data) =>
    dispatch(actions.adminSendDoVerificationEmailRequest(data)),
  // updateLinkRequest: (data) => dispatch(actions.updateLinkRequest(data)),
  // myLinksRequest: (data) => dispatch(actions.myLinksRequest(data)),
  // deleteLinkRequest: (data) => dispatch(actions.deleteLinkRequest(data)),
  // updateLinkStatusRequest: (data) =>
  //   dispatch(actions.updateLinkStatusRequest(data)),
  // myStatsRequest: (data) => dispatch(actions.myStatsRequest(data)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Admin));
