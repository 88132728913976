import React from "react";
import { withRouter } from "react-router-dom";
import { Layout } from "antd";
import HeaderMain from "../components/HeaderMain";
import FooterMain from "../components/FooterMain";
import Search from "../components/search";

const { Content } = Layout;

class SearchContainer extends React.Component {
  render() {
    return (
      <Layout id="search-page">
        <HeaderMain />
        <Content className="page-content">
          <Search />
        </Content>
        <FooterMain />
      </Layout>
    );
  }
}

// export default SearchContainer;

export default withRouter(SearchContainer);
