import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  Form,
  Input,
  Select,
  Row,
  Col,
  Checkbox,
  Button,
  AutoComplete,
  Spin,
} from "antd";

import * as actions from "../../redux/actions";

const { Option } = Select;

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

class FormNewLocation extends React.Component {
  constructor() {
    super();
    this.state = {
      //   isMyProfileFetched: false,
      initialValues: {
        name: "",
        mobileNumber: "",
        myLocationId: "",
      },
    };

    this.editProfileFormRef = React.createRef();
  }

  submitForm = (values) => {
    // console.log(values);
    //this.props.newParkingRequest(values);
    this.props.newLocationRequest(values);
  };

  componentDidMount() {
    // this.props.getAllLocationsRequest();
    // this.props.getMyProfileRequest();
    // this.setState({
    //   editLinkData: this.props.editLinkData,
    // });
  }

  //   static getDerivedStateFromProps(props, current_state) {
  //     if (current_state.editLinkData !== props.editLinkData) {
  //       return {
  //         editLinkData: props.editLinkData,
  //       };
  //     }
  //     return null;
  //   }

  componentDidUpdate(prevProps) {
    // console.log(prevProps.myProfile);
    // console.log(this.props.myProfile);
    // if (prevProps.myProfile !== this.props.myProfile) {
    //   const { myProfile } = this.props;
    //   this.setState({
    //     isMyProfileFetched: true,
    //     initialValues: {
    //       name: myProfile.name || "",
    //       mobileNumber: myProfile.mobileNumber || "",
    //       myLocationId: myProfile.myLocationId || "",
    //     },
    //   });
    //   // console.log(this.editProfileFormRef);
    //   // console.log(this.editProfileFormRef);
    //   // console.log(this.editProfileFormRef);
    //   // console.log(this.editProfileFormRef);
    //   // console.log(this.editProfileFormRef);
    //   if (this.editProfileFormRef.current) {
    //     this.editProfileFormRef.current.setFieldsValue({
    //       name: myProfile.name || "",
    //       mobileNumber: myProfile.mobileNumber || "",
    //       myLocationId: myProfile.myLocationId || "",
    //     });
    //   }
    // }
  }

  render() {
    const { newLocation } = this.props;
    // console.log(newLocation);
    // const { isLoading } = this.props.locations;
    // const { isMyProfileFetched, initialValues } = this.state;
    // let initialValues = {};

    // let initialValues = {
    //   name: myProfile.name || "",
    //   mobileNumber: myProfile.mobileNumber || "",
    //   myLocationId: myProfile.myLocationId || "",
    // };

    // console.log("isMyProfileFetched---- " + isMyProfileFetched);
    // console.log(initialValues);

    return (
      <Row id="form-add-newparking">
        <Col xl={24} md={24} xs={24}>
          <h3>Add my society</h3>
          {/* {!isMyProfileFetched ? (
            <Spin />
          ) : ( */}
          <Form
            ref={this.editProfileFormRef}
            {...formItemLayout}
            // form={form}
            name="editProfile"
            onFinish={this.submitForm}
            // initialValues={initialValues}
            scrollToFirstError
          >
            <Form.Item
              name="fullAddress"
              label="Full Address"
              rules={[
                {
                  required: true,
                  message: "Enter full address",
                },
              ]}
            >
              <Input autoComplete={false} />
            </Form.Item>

            <Form.Item {...tailFormItemLayout}>
              {newLocation && newLocation.isLoading ? (
                <Spin />
              ) : (
                <Button type="primary" htmlType="submit">
                  Add my society
                </Button>
              )}
            </Form.Item>
          </Form>
          {/* )} */}
        </Col>
      </Row>
    );
  }
}

// export default FormAddNewShortShout;

const mapStateToProps = (state) => ({
  //   updateMyProfile: state.auth.updateMyProfile || false,
  //   loggedUserData: state.auth.login.data || false,
  newLocation: state.locations.newLocation || {},
  //   myProfile: state.auth.myProfile.data || {},
});

const mapDispatchToProps = (dispatch) => ({
  //   getAllLocationsRequest: (data) =>
  //     dispatch(actions.getAllLocationsRequest(data)),
  //   newParkingRequest: (data) => dispatch(actions.newParkingRequest(data)),
  //   getMyProfileRequest: (data) => dispatch(actions.getMyProfileRequest(data)),
  newLocationRequest: (data) => dispatch(actions.newLocationRequest(data)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(FormNewLocation)
);
