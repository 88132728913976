import React from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { Row, Col, AutoComplete, Button } from "antd";
import * as actions from "../../redux/actions";
import { getLoggedUser, showNotification } from "../../services";

class SearchBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedSearchLocation: "",
    };
  }

  componentDidMount() {
    this.props.getAllLocationsRequest();
  }

  _openSearchPage = (data) => {
    let locationFullName = data.value;
    locationFullName = locationFullName.replace(/[^a-z0-9]+/gi, "-");
    let url = "/society/" + locationFullName + "-" + data.id;
    this.props.history.push(url);
  };

  _onSearchSelect = (id, option) => {
    this.setState(
      {
        selectedSearchLocation: option.value,
      },
      () => {
        this._openSearchPage(option);
      }
    );
  };

  _listMySociety = () => {
    let check = getLoggedUser();
    if (check) {
      this.props.history.push(`/mydashboard-request-new-location`);
    } else {
      showNotification({
        type: "warning",
        title: "Please login!!",
      });
    }
  };

  render() {
    let { locations } = this.props;
    const searchOptions = [];
    locations.forEach((location) => {
      searchOptions.push({
        value:
          location.locationName +
          ", " +
          location.locationAddress +
          ", " +
          location.locationCity +
          ", " +
          location.locationState,
        id: location._id,
      });
    });

    return (
      <Row id="landing-search-box">
        <Col className="" xl={24} md={24} xs={24}>
          <AutoComplete
            className="location-search-box"
            // value={this.state.selectedSearchLocation}
            options={searchOptions}
            placeholder="Search Society Name..."
            filterOption={(inputValue, option) =>
              option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !==
              -1
            }
            onSelect={this._onSearchSelect}
          />
          <div id="list-my-location">
            {/* <a href="mailto: parkinghunter@outlook.com?subject=Add My Society">
              *List My Society
            </a> */}
            {/* <Link to="/mydashboard-request-new-location"> */}
            <Button type="link" onClick={this._listMySociety}>
              {" "}
              *List My Society
            </Button>
            {/* </Link> */}
          </div>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (state) => ({
  locations: state.locations.locations.data || [],
});

const mapDispatchToProps = (dispatch) => ({
  getAllLocationsRequest: (data) =>
    dispatch(actions.getAllLocationsRequest(data)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SearchBox)
);
